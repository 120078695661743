* {
  padding: 0px;
  margin: 0px;
}

.center {
  margin: 1rem;
  width: 100%;
  text-align: center;
  color: #ee8a53;
}

.heading {
  margin: 1rem;
}

.table {
  width: 95%;
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  margin-left: 1rem;
}

.table thead tr {
  background-color: #ee8a53;
  color: #ffffff;
  text-align: center;
}

.table th {
  padding: 6px 8px;
  font-size: 17px;
}
.table td {
  padding: 6px 8px;
}

.table tbody tr {
  border-bottom: 1px solid #fff;
  text-align: center;
}

.table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.table tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.table tbody tr:last-of-type {
  border-bottom: 2px solid #fca311;
}

.table tbody tr.active-row {
  font-weight: bold;
  color: #fca311;
}

.chart {
  font-size: 12px;
}

.chart-heading {
  color: #000;
}

.summary-chart {
  height: 550px !important;
}

.summary-chart .recharts-wrapper {
  height: 500px !important;
}

.summary-chart .recharts-wrapper .recharts-surface {
  margin-top: -200px;
  height: 700px !important;
}

@media screen and(min-width: 600px) {
  .chart {
    font-size: 8px;
  }
}
