:root {
  --bg: #ecffff;
  --white: #ffffff;
  --reddark: #ff0000;
  --red: #f5c2c7;
  --gray: #85a0c8;
  --graylight: #e5e5e5;
  --blue: #00aeef;
  --green: #4cb998;
  --greendeep: #359d9e;
  --deepblue: #3b5998;
  --orange: #ee8a52;
  --orangelight: #ffe7d4;
  --lightgry: #7a7fa8;
  --black: #43455e;
  --grycontain: #f6f8fb;
  --lightgreen: #f6f9fe;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
}

.center {
  text-align: center;
}

body {
  font-family: "Roboto", sans-serif;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="text"]:focus,
input[type="number"]:focus{
    box-shadow: none;
    outline: 0 none;
    
}

.clearall {
  clear: both;
  width: 100%;
  float: left;
}

.directory {
  display: flex;
  justify-content: center;
  align-items: center;
}

.yellow-lines {
  color: rgb(255, 196, 0);
  margin: 0 auto;
  width: 200px;
  border-top: 7px solid;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--gray);
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--gray);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--gray);
}

* {
  margin: 0;
  padding: 0;
}

.container_bg {
  background-color: var(--white);
  padding-top: 5%;
  overflow-y: hidden;

  /* box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.08); */
}

/* header> */
.navbar  .dropdown-toggle{
  font-size: 15px !important;
  font-weight: 400;
  color: #000;
  
}
.navbar .column .nav-link{
  font-size: 15px !important;
  font-weight: 600;}
.headerbg {
  padding-top: 10;
  padding-bottom: 10;
}

.header_tops {
  text-align: center;
}

.logo {
  margin: 15px 0;
}

.navbar {
  position: fixed;
  top: 0px;
  z-index: 9999;
  background: url('../assets/img/bannerbg.svg');
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.08);
  color: black;
}

.navbarToggler {
  border: none;
  background: none;
}

.nav-item a {
  text-decoration: none;
  color: black;
  font-size: 17px;
  font-style: "Roboto";
  
}

.header {
  display: flex;
  list-style: none;
  float: right;
}

.nav_icon {
  margin-left: 110px;
}

.nav_icon i {
  margin-top: 10px;
}

.nav-item {
  padding: 10px 7px;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400 !important;
}

.nav-item a:hover,
.nav-link.active {
  color: #235289 !important;
}

a:hover {
  color: #235289
}

.dolor .p {
  font-size: 10px;
}

.header_right li a {
  text-decoration: underline;
  padding-bottom: 2px;
  color: #2A4D7C;
  font-size: 18px;
  font-weight: bold;
}

.header_right li a:hover {
  color: #BBEBFA;
}

.navigation i {
  padding: 20px 10px 20px 40px;
  font-size: 23px;
}

/* header end> */
.chip, .singleChip {
  white-space: inherit !important;
}

/* groupview */
.allergy {
  background: var(--white);
  box-shadow: 0px 4.82036px 38.5629px rgba(31, 88, 141, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 0 !important;
}
.allergy .ui.styled.accordion{
  width: 100%;
}
.allergy .sacn_testad i{
  top: 10px;
}
.allergy .ui.styled.accordion .title{
  background: #F7F7F7;
  padding: 10px!important;
}
.allergy h5 {
  font-weight: 500;
  font-size: 24px;
  color: #161C2D;
  font-family: 'Roboto';
}

.title {
  position: relative;
}

.sacn_testad {
  background: #F7F7F7;
  border-radius: 5px;
  padding: 10px;
  margin: 16px 0;
}

.sacn_testad span {
  font-size: 18px;
  font-weight: 400;
  color: var(--black);
  font-family: 'Roboto';
}

.sacn_testad i {
  position: absolute;
  right: 0;
  top: 2px
}

.scan_allergy {
  background: var(--white);
  margin-top: 20px;
  box-shadow: 0px 4.82036px 38.5629px rgba(31, 88, 141, 0.1);
  border-radius: 10px;
  padding: 10px;
}

.scan_allergy h5 {
  font-weight: 500;
  font-size: 24px;
  color: #161C2D;
  font-family: 'Roboto';
}

.scan_allergy span {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 18px;
  color: #161C2D;
}

.scan_allergy h6 {
  font-family: 'Roboto';
  font-size: 13px;
  line-height: 16px;
  color: #43455E;
}

/* notification page */
.notification_sec {
  background: #ffffff;
  box-shadow: 0px 4.82036px 38.5629px rgb(31 88 141 / 10%);
  border-radius: 9.64072px;
  padding: 4% 30px;
  margin-top: 5%;
  margin-bottom: 5%;
}

.notification_sec .ui.list .list>.item>img.image+.content, .ui.list>.item>img.image+.content {
  width: 88%;
  font-size: 18px;
  font-weight: 400;
}

.notification_sec .notification_box {
  position: relative;
}

.noti_icon {
  background-color: var(--orangelight);
  border-radius: 100%;
  cursor: pointer;
  padding: 4px 7px;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

.notification_box {
  background: #FFFFFF;
  box-shadow: 0px 5px 30px rgba(31, 88, 141, 0.1);
  border-radius: 10px;
  padding: 27px !important;
}

/* home page  start*/

.banner_bg {
  background: url("../assets/img/bannerbg.svg") no-repeat;
  min-height: 562px;
  background-size: cover;
  position: relative;
}
.home_page{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.Myscans {
  width: 36%;
  padding: 50px 0;
  margin-left: 14%;
  margin-top: 5%;
  position: relative;
}

.Myscans h5 {
  font-size: 43px;
  color: #235289;
}

.Myscans p {
  padding-top: 0%;
  font-size: 12px;
  color: #235289;
}

.Myscans .homeSerch {
  background: var(--white);
  border-radius: 45px;
  padding: 3px;
  width: 356px;
  display: flex;
  align-items: center;
}

.homeSerch .searchbar {
  position: relative;
}

.homeSerch i {
  padding: 7px 10px;
  font-size: 23px;
  color: var(--greendeep);
}

.homeSerch .autocompleteBox {
  display: inline-block;
  position: relative;
  width: 34%;
  z-index: 15;
}

.autocompleteBox {
  align-self: center;
}

.homeSerch .autocomplete {
  position: absolute;
  min-width: 400px;
  background: var(--white);
}

.homeSerch .autocompleteBox input {
  outline: none;
  border: none;
  padding: 0;
}

.homeSerch .homeSerchName {
  border: none;
  width: 90%;
  outline: none;
  font-size: 15px;
}

.color-black {
  color: var(--black) !important;
}

.Myscans .labSearch {
  float: right;
  border: none;
  background: none;
  padding: 3px 0px;
}

.Myscans .labSearch i {
  color: var(--white);
  font-size: 18px;
  padding: 10px;
  background: var(--greendeep);
  border-radius: 100%;
  /* margin-top: 3px; */
  margin-right: 3px;
}

.search-flask-icon {
  float: left;
  font-size: 21px;
  margin: 3px 0 0 5px;
  color: var(--greendeep);
}

.home-search {
  margin-left: 2% !important;
  width: 70% !important;
}

.display-flex {
  display: flex !important;
}

.world {
  float: left;
  color: var(--gray);
  font-size: 16px;
  padding-left: 10px;
}

.book_button {
  border-radius: 25px;
  text-align: center;
  background: #235289;
  border: none;
  padding: 10px;
  color: #ffffff;
  height: 45px;
  width: 150px;
  font-size: 15px;
  margin-top: 20px;
  margin-right: 20px;
  font-weight: 500;
}
.book_button1 {
  border-radius: 15px;
  text-align: center;
  background: #EE8A53;
  border: none;
  padding: 16px;
  color: #ffffff;
  height: auto;
  width: 250px;
  font-size: 30px;
  font-weight: 600;
  margin-top: 5%;
  margin-left: 5%;


}

.diogno_sec {
  padding:0;
  clear: both;
}

.nav_logo {
  margin: 0 auto;
  display: block;
}

.round-box {
  text-align: center;
}
.round-box img{width: 37%;
}
.round-box img.ui.image{
  display: initial;
}

.directory h5 {
  text-align: center;
  color: #131210;
  padding-top: 10px;
  font-size: 42px;
  padding: 6% 0;
}

.nav_img {
  height: 20px;
  width: 20px;
  margin-left: 45%;
  margin-top: 20%;
}

.Reliable {
  text-align: center;
  padding: 0 0 8px 0;
}

.Reliable h5 {
  font-weight: bold;
  font-size: 16px;
}

.ipsum {
  color: black;
  font-size: 15px;
  text-align: center;
}

.conten2_bg {
  background: url("../assets/img/home_services.png") no-repeat;
  background-size: contain;
  min-height: 650px;
  margin-top: 25px;
  height: 100px;
}
.conten3_bg {
  background: url("../assets/img/textbg.png") no-repeat;
  background-size: contain;
  min-height: 600px;
  margin-top: 25px;
  height: 100px;
}
.conten4_bg {
  background: url("../assets/img/textbg2.png") no-repeat;
  background-size: contain;
  min-height: 600px;
  margin-top: 2px;
  height: 150px;
}
.incididunt_text{
  padding-top: 1.5%;
  margin-left: 4%;
  width: 100%;
  color:white;
  text-align: justify;
  font-size: 16px;
  
}
.incididunt_text h2 {
font-size: 22px;
}

.img_path{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.img_path1{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.path_image{
  height: 60px;
  width: 60px;
  margin-top: 2%;
}
.path_image1{
  height: 50px;
  width: 50px;
  margin-top: 2%;
  margin-left: 5%;
}
.path_text{
  margin-left: 40%;
  color: #2A4D7C;
  float: right;
  margin-top: 8%;
  font-weight: 600;
  text-align: end !important;
  width: 100%;
}
.path_text2{
  margin-left: 0%;
  color: #2A4D7C;
  margin-top: 8%;
  font-weight: 600;
  margin-right: 18%;
  width: 100%;
}
.people_top {
  margin-top: -20%;
}

.incididunt {
  margin-top: 5%;
  margin-left: 10%;
  width: 100%;
}

.incididunt h5 {
  font-size: 42px;
  color: var(--bg);
}
.servicesss{
  font-size: 42px;
  color: var(--bg);
}
.servic {
  font-size: 17px;
  color: var(--bg);
  margin: 20px 0;
}

.radiology {
  float: left;
  margin-right: 5px;
}

.pathology {
  float:left;
  justify-content: space-between ;
  margin-right: 3%;
  margin-top: 55px;
}

.heading {
  text-align: center;
  color: var(--white);
  margin-top: 20px;
}

.test button {
  border-radius: 25px;
  text-align: center;
  background: #f8cc60;
  border: none;
  padding: 3% 13%;
  font-size: 15px;
  margin-top: 20px;
  color: #816010;
}

.people h5 {
  font-size: 36px;
  text-align: center;
}

.dolor {
  font-size: 26px;
  color: var(--orange);
  font-weight: bold;
  text-align: center;
}

.Testimonial {
  background: url("../assets/img/home _images.png") no-repeat;
  min-height: 326px;
  /* margin-bottom: 5%; */
  background-size: contain;
  margin-top: 4%;
}

.testimonial-carousel .owl-nav button:hover {
  background: transparent !important;
  color: #000 !important;
}

.testimonial-carousel .owl-nav {
  position: absolute !important;
  width: 100% !important;
  bottom: 25% !important;
}

.text_sec {
  width: 48%;
  margin: 0 auto;
}

.josh_sec {
  /* width: 70%; */
  text-align: center;
  margin: 0 auto;
  padding-top: 20%;
}

.img_sec {
  float: left;
}

.right_text {
  margin-left: 10px;
}

.JOSH {
  font-size: 18px;
}

.RAISED {
  font-size: 15px;
  color: var(--orange);
}
.contactAddress{
  width: 80%;
  margin: 0 auto;
  color: var(--orange);
  padding-top: 20px;
  text-align: left;
}
.Testimonial p {
  font-size: 18px;
  text-align: center;
  margin-top: 10%;
  color: var(--gray);
}

.icon-box {
  width: 25%;
  margin: 0 auto;
}

.round-sec i {
  float: left;
  background: #ffffff;
  border: 1px solid #edf1fd;
  box-shadow: 0px 24px 30px rgba(68, 75, 140, 0.1);
  border-radius: 100%;
  padding: 14px 20px;
  margin-top: 16px;
  margin-right: 5px;
}

.contact-us-form {
  display: flex;
  flex-wrap: wrap-reverse;
  border-radius: 25px;
}

.elit {
  background: #FFFFFF;
  width: 50%;
  padding: 10%;
  margin-top: 5%;
}
.fa-user-circle{
  color: white;
  font-size: large;
}

.elit p {
  font-size: 13px;
  color: black;
}
.elit input {
  font-size: 13px;
  color: white;
}
.elit textarea {
  font-size: 13px;
  color: white;
}

.tempor {
  background: #eff4fb;
  width: 50%;
  margin-top: 5%;
  border-radius: 35px;
}

.Contact {
  color: black;
  font-size: 36px;
}

.consectetur p {
  font-size: 14px;
  color: var(--white);
}

.homeFormBox {
  background: #284A78;
  color: #6ffeff;
  margin: 5px 0;
  border-radius: 20px;
  padding-left: 20px;
}

.homeFormBox ::placeholder {
  color: #ffffff;
}

.addres {
  border: none;
  padding: 10px;
  font-size: 16px;
  background: none;
  outline: none;
  width: 90%;
}

.homeFormBox.error {
  border: solid 1px #534d4d;
}

.send_img {
  height: 25px;
}

.send_box {
  border: none;
  padding-left: 7px;
  background: var(--white);
  font-size: 24px;
  color: var(--greendeep);
  font-weight: bold;
}

.labore {
  background: #284A78;
  border-radius: 20px;
  padding: 10px;
  margin-top: 10px;
  height: 155px;
}

.labore ::placeholder {
  color: #ffffff;
}

.labore textarea {
  width: 100%;
  min-height: 130px;
}

.message_box {
  background: #20898a;
  border: none;
  font-size: 18px;
  padding-top: 10px;
}

.robo {
  text-align: center;
  padding-top: 40px;
}

.conten_img {
  height: 170px;
}

.tempor h6 {
  padding-top: 10px;
  font-size: 25px;
}

.Chat {
  background: linear-gradient(134.17deg, #284A78 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 40px;
  width: 36%;
  border: none;
  padding: 10px 0;
  color: var(--bg);
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}
.fa-phone{
  color: white;
  font-size: 18px;
}
.fa-envelope-o{
  color: white;
  font-size: 20px;
}

.OR {
  font-size: 24px;
}

.envelope i {
  background: #284A78;
  box-shadow: 0px 24px 30px rgb(68 75 140 / 10%);
  border-radius: 100%;
  padding: 19px 20px;
  margin: 10px 0;
}

.tempor h5 {
  font-size: 13px;
}

.gmail {
  font-size: 11px;
  color: var(--orange);
  font-weight: bold;
}

.veniam {
  background: var(--white);
  width: 43%;
  text-align: center;
  cursor: pointer;
  margin: auto;
  border-radius: 31px;
  margin-top: 10%;
  padding: 16px 10px;
}

.veniam i {
  font-size: 25px;
  color: var(--greendeep);
}

/* home page  end*
/*Content layout1*/
.content_layout1{
  background: url(./img/content_bg.png) no-repeat;
  background-size: contain;
  min-height: 3870px;
  margin-top: 5%;
}
.content_images{
  height: 80px;
  width: 80px;
}
.content_blocks{
  margin-left: 8%;
  margin-right: 5%;
  
}
.content_blocks2{
  margin-left: 8%;
  margin-right: 5%;
}
.content_image_names{
  font-size: 28px;
  vertical-align: middle;
  margin-left: 2%;
  font-weight: 600;
  color: #ffffff;
}
.content_image_description{
  color: white;
  text-align: justify;
  vertical-align: bottom;
  /* line-height: normal; */
}
/*Radiology Content Layout */
.content_images_rad{
  height: 80px;
  width: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.content_blocks_rad{
  margin-left: 15%;
  margin-right: -10%;
}
.content_blocks2_rad{
  margin-left: 15%;
  margin-right: -10%;
  
}
.content_image_names_rad{
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
  display: block;
  text-align: center;
}
.content_image_description_rad{
  color: white;
  text-align: justify;
  vertical-align: bottom;
  /* line-height: normal; */
}
@media only screen and (max-width:420px) {
  .content_layout1{
    background: url(./img/content_mobile.png) no-repeat;
    background-size: cover;
    min-height: auto;
    margin-top: 5%;
  }
  .content_images{
    height: 55px;
    width: 55px;
  }
  .content_blocks{
    margin-left: 8%;
    margin-right: 5%;
    margin-bottom: 5%;
  }
  .content_blocks2:nth-child(1){
    margin-bottom: 0;
  }
  .content_blocks2{
    margin-left: 8%;
    margin-right: 5%;
    margin-bottom: 5%;
  }
  .content_image_names{
    font-size: 22px;
    vertical-align: middle;
    margin-left: 2%;
    font-weight: 600;
    color: #ffffff;
  }
  .content_image_description{
    color: white;
    text-align: justify;
    vertical-align: bottom;
    /* line-height: normal; */
  }
  /*Radiology Content Layout */
  .content_images_rad{
    height: 80px;
    width: 80px;
    margin-bottom: -7%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .content_blocks_rad{
    margin-left: 4%;
    margin-right: -4%;
    margin-bottom: 5%;
  }
  .content_blocks_rad:nth-child(3){
    margin-bottom: 0;
  }

  .content_blocks2_rad{
    margin-left: 4%;
    margin-right: -4%;
    margin-bottom: 5%;
  }
  .content_blocks2_rad:nth-child(3){
    margin-bottom: 0;
  }
  .content_image_names_rad{
    font-size: 28px;
    font-weight: 600;
    margin-bottom: -7%;
    color: #ffffff;
    display: block;
    text-align: center;
  }
  .content_image_description_rad{
    color: white;
    text-align: justify;
    vertical-align: bottom;
    /* line-height: normal; */
  }
}
/* Popular Diagnostic Test block */
.populartestblock{
  background: url(../assets/img/populartestbg3.svg) no-repeat;
  background-size: contain;
  min-height: 600px;
  margin-left: 5%;
  margin-right: 5%;
}
.populartestheading{
  margin: 5%;
  padding-top: 2.5%;
}
.populartestheading p {
font-size: 32px;
font-weight: 600;
line-height: 20px;
}
.populartestlist{
  justify-content: space-around;
  margin-left: 5% !important;
}
.popular-link:hover,
.popular-link.active {
  color: #194A84 !important;
}
.popular-link{
  color: white !important;
  font-size:16px;
  font-weight: 600;
  line-height: 40px;
}
@media only screen and (max-width:420px) {
  .populartestblock{
    background: url(../assets/img/populartestbg_mobile_360.svg) no-repeat;
    background-size: contain;
    min-height: 900px;
    margin-left: 5%;
    margin-right: 5%;
  }
  .populartestheading{
    margin: 5%;
    padding-top: 3.5%;
  }
  .populartestheading p {
    font-size: 14px;
    font-weight: 600;
    line-height: 8px;
    }
  .populartestlist{
      justify-content: space-around;
      margin-left: 2% !important;
    }
    .popular-link{
      color: white !important;
      font-size:12px;
      font-weight: 600;
      line-height: 1;
    }
}
@media (max-width: 375px){
  .popular-link{
    color: white !important;
    font-size:12px;
    font-weight: 600;
    line-height: 0.5;
  }
}
@media (max-width: 360px){
  .popular-link{
    color: white !important;
    font-size:11px;
    font-weight: 600;
    line-height: 0.5;
  }
}


/* footer> */

.box {
  margin: 0 auto;
  width: 75%;
  padding: 5% 0;
}

.box h3 {
  color: black;
  font-size: 18px;
}
.our_branches{
  text-align: center;
  font-size: xx-large;
  font-weight: 600;
  margin-bottom: 5%;
}

.stack_list_1{
  list-style: none;
  font-size: 16px;
  margin-left: 10%;
  width: 100%;
}
.stack_list_1 li {
 padding: 5px;
}
.stack_list_1 li a {
  color: #000;
}
.stack_list_1 li a:hover {
  text-decoration: underline;
}
.branch_list{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.branch_grid{
  margin-left: 5%;
}
.karnataka{
  list-style: none;
  font-size: 22px;
  font-weight: 800;
  text-decoration: underline;
}
.andhrapradesh{
  list-style: none;
  font-size: 22px;
  font-weight: 800;
  text-decoration: underline;
}
.Benefit {
  padding-top: 10px;
  font-size: 16px;
  color: black;
}
.menu_ite{
  margin-top: 3%;
  color: black;
}
.menu_ite p:link {
  color: black !important;
}
.menu_ite :link{
  color: black !important;
}
.second_menu_item{
  margin-top: 11.5%;
}
.branch_menu_item{
  margin-top: 9.5%;
}
.logo_safe{
 display: flex;
 flex-direction: column;
 margin-left: 4%;
margin-top: 4%;
}
.stack_menu{
  display: flex;
  flex-direction: row;
  justify-content: space-between ;
}
.stack_menu2{
  display: flex;
  flex-direction: row;
  justify-content: space-around ;
  font-weight: 500;
}
.states{
  font-size:x-large;
  font-weight: 800;
}

.footerbg {
  background: #B1D0F9;
  border-radius: 35px;

  /* background: linear-gradient(134.17deg, #55C595 4.98%, #359D9E 94.88%); */
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
}

.footerbg .border {
  margin-bottom: 10px;
}

.footer {
  background-color: #04304f;
  color: #eff4fb;
  padding-top: 7%;
}

.footer_left {
  padding-left: 30;
}

.About {
  font-size: 12px;
}
.contact_form{
  padding-top: 5% !important;
}

.header_bottom {
  list-style: none;
  padding: 0;
}

.nav-list {
  padding-top: 6px;
  padding-bottom: 6px;
}

.nav-list a {
  text-decoration: none;
  color: black;
  font-size: 14px;
}

.chart_img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.All {
  padding: 10px;
  font-size: 14px;
  color: black;
}

.Call {
  color: black;
}

.live i {
  color: black;
  font-size: 18px;
  padding-right: 9px;
}
.centerMenu{
  padding-left: 50px;
}
.livechart {
  float: left;
  margin-right: 10px;
}

.live {
  font-size: 14px;
  padding-top: 2px;
  color: black;
  cursor: pointer;
}

.live a {
  color: black;
}

.cardimg_box {
  text-align: right;
}

.cardimg_box img {
  margin: 0 5px;
}

/* startaboutpage */
.container-fluid {
  background-color: #ffff;
}

.aboutbanner {
  background: url(../assets/img/aboutbanner.svg);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 450px;
}

.established {
  color: #284A78;
  width: 75%;
  padding-left: 3%;
  padding-right: 20%;
  padding-top: 1%;
}

.established h4 {
  font-size: 40px;
  font-weight: 600;
}

.navication {
  padding: 0 3%;
  width: 99%;
}

.established p {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 5px 0;
}

.distracted {
  float: left;
  width: 16%;
  margin: 10px 20px;
  text-align: center;
}

.culchure_img {
  width: 100%;
  margin-left: 15px;
}

.certify {
  padding-top: 16%;
}

.certify_text {
  padding-top: 34% !important;
}

.distracted h5 {
  font-size: 24px;
  font-weight: 500;
  color: black;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.distracted p {
  font-size: 15px;
  font-weight: 400;
  color: rgba(22, 28, 45, 0.6);
}

.team {
  width: 70%;
  margin-top: 5%;
}

.team h5 {
  font-size: 40px;
  font-weight: 700;
  color: black;
}

.team p {
  font-size: 19px;
  font-weight: 400;
  color: rgba(22, 28, 45, 0.6);
  padding: 15px 0;
}

.john_peter {
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(31, 88, 141, 0.1);
  border-radius: 20px;
  text-align: center;
  min-height: 300px;
  padding:15px;
}

.john_peter h3 {
  font-size: 24px;
  font-weight: 400;
  color: black;
  padding-top: 20px;
}

.john_peter h5 {
  font-size: 18px;
  font-weight: 500;
  color: #359d9e;
}

.john_peter p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(22, 28, 45, 0.6);
}

.twitter_icon i {
  color: rgba(0, 0, 0, 0.6);
  padding: 0 10px;
}

.readable h3 {
  font-size: 30px;
  font-weight: 700;
  color: black;
}

.readable p {
  font-size: 16px;
  font-weight: 400;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.6);
}

/* end aboutpage */

/* login */
.logimg {
  width: 570px;
  height: auto;
  margin-left: 10%;
}

.log_right {
  text-align: center;
  background: var(--white);
  box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 15%;
  /* margin-top: 10%; */
}

.input-box .ui.labeled.input:not([class*="corner labeled"]) .label:first-child+input {
  /* border-top-right-radius: 40px; */
  /* border-bottom-right-radius: 40px; */
  border: none;
  width: 90%;
  padding: 0;
  background: none;
  font-size: 13px;
  color: #85A0C8;
  padding-left: 6px;
}

.input-box .ui.input {
  border: 1px solid var(--greendeep);
  border-radius: 22px;
  width: 100%;
  padding: 7px;
}

.input-box .ui.input>input {
  max-width: 82%;
}

.input-box .ui.labeled.input:not([class*="corner labeled"]) .label:first-child {
  background: none;
  border-right: 1px solid var(--greendeep);
  padding: 5px 6px;
}


.log_right h3 {
  font-size: 48px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}

.log_right p {
  font-size: 15px;
  color: var(--lightgry);
  padding: 15px 0 20px 0;
}

.log_right p span {
  color: red;
}

.log_inputbg {
  border: 1px solid var(--greendeep);
  padding: 10px 15px;
  border-radius: 22px;
}

.log_border {
  float: left;
  border-right: 1px solid var(--greendeep);
  padding-right: 10px;
}

.log_inputbg .loginput {
  border: none;
}

.logsec button.reset {
  background: linear-gradient(134.17deg, #f9f9f9 4.98%, #282828 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.3);
  border-radius: 40px;
  width: 100%;
  border: none;
  padding: 10px 0;
  color: var(--white);
  font-size: 15px;
  margin: 10px 0 0 0;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.editProfile button.signupSubmit,
.logsec button.signupSubmit {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.3);
  border-radius: 40px;
  width: 100%;
  border: none;
  padding: 10px 0;
  color: var(--white);
  font-size: 20px;
  margin: 15px 0;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.log_right h4 {
  font-size: 12px;
  color: var(--lightgry);
  margin-top: 15px;
}

.log_right h4 a {
  color: var(--orange);
  text-decoration: underline;
}

.code {
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--lightgry);
}

.input-box input {
  flex-grow: 1;
  color: #85a0c8;
  font-size: 18px;
  border: none;
  outline: none;
  padding: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.input-box span {
  color: var(--black);
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

/* new */

.input-box:focus-within {
  border-color: #777;
}

.input_sec {
  background-color: #dee7f4;
  border-radius: 25px;
  padding: 13px;
  width: 70%;
  margin: 0 auto;
}

.input_sec p {
  float: left;
  color: #43455e;
  font-size: 20px;
}

.input_sec .border {
  border-left: 2px;
  border-width: 2px;
}

.inputnum {
  border: none;
  color: #85a0c8;
  background: none;
  font-size: 18px;
  border-left: 1px;
}

.butt_login {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.3);
  border-radius: 40px;
  border: none;
  padding: 9px 0;
  color: var(--white);
  font-weight: bold;
  width: 70%;
  margin: 20px 16px;
  font-size: 24px;
}

.Terms {
  background-color: #dee7f4;
  width: 43%;
  margin: 0 auto;
}

.Use {
  font-size: 18px;
  padding-top: 15px;
  color: #ee8a52;
}

.Profiles {
  width: 63%;
  margin: 0 auto;
  padding-top: 20px;
  clear: both;
}

.Profiles_bottom {
  width: 28%;
  margin: 0 auto;
  padding-top: 20px;
}

.twitter_sec {
  background: #00aeef;
  box-shadow: 0px 10px 20px rgba(0, 174, 239, 0.2);
  border-radius: 25px;
  width: 42%;
  float: left;
  margin: 0 10px;
  padding: 10px;
  color: var(--white);
}

.twitter_se i {
  float: left;
}

.googlecl {
  background: #4cb998;
  box-shadow: 0px 10px 30px rgba(0, 157, 101, 0.3);
}

.twitercl {
  background: #3b5998;
  box-shadow: 0px 10px 20px rgba(0, 71, 255, 0.2);
  border-radius: 25px;
  padding: 10px;
  color: var(--white);
}

.twitercl i {
  padding-right: 8px;
}

/* popupsec stat */

.popupbg {
  background-color: var(--orange);
  border-radius: 30px;
  padding: 10px;
}

.uplode_left p {
  font-size: 16px;
  color: var(--lightgry);
}

.pop_sec {
  text-align: center;
}

.pop_sec p {
  color: var(--greendeep);
  font-size: 30px;
  font-weight: 600px;
}

.crossbg {
  float: right;
  margin-right: 7px;
}

.crossbg i {
  background-color: #ffe7d4;
  border-radius: 100%;
  font-size: 40px;
  color: #ff4f3e;
  cursor: pointer;
  padding: 10px 14px;
}

.uplode_sec {
  padding: 25px 0;
  width: 85%;
  margin: 0 auto;
  text-align: center;
}

.uplode_left {
  background-color: #ccc;
  border: 2px dashed #eff4fb;
  border-radius: 5px;
  padding: 22px 0;
}

.uplode_left i {
  font-size: 60px;
  color: var(--greendeep);
}

.uplode_left h3 {
  font-size: 20px;
  color: var(--greendeep);
  font-weight: bold;
  text-decoration: underline;
}

.centerbg {
  background-color: #f8cc60;
  width: 2px;
  height: 43%;
}

.or {
  color: #7a7fa8;
}

.uplode_right {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 10px;
  padding: 32px 0;
}

.uplode_right i {
  font-size: 60px;
  color: var(--white);
}

.uplode_right p {
  font-size: 16px;
  padding-top: 10px;
}

.submit {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 40px;
}

/* popupsec end */

/* opt sectiom */

.inputbg {
  float: left;
  outline: none;
  border: solid 1px var(--greendeep);
  background: var(--white);

  text-align: center;

  border-radius: 5px;
  padding: 13px 0;
  width: 17%;
  margin: 0 3%;
}

.otpmainbox {
  display: flex;
  justify-content: center;
}

.log_right h1 {
  font-weight: normal;
}

.log_right h2 {
  font-size: 20px;
  color: var(--orange);
  padding-bottom: 20px;
  text-decoration: underline;
  font-weight: 500;
}

.log_right input {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.buttonbox {
  clear: both;
  padding: 15px 0;
  margin: 0 auto;
}

.buttonbox .buttonotp {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgb(0 151 69 / 30%);
  border-radius: 40px;
  width: 100%;
  border: none;
  padding: 10px 0;
  color: var(--white);
  font-size: 20px;
  margin: 35px 0;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

/* new */

/* signUp sec */

.erro {
  color: var(--reddark) !important;
  padding: 10px;
}

.loginbg_sec {
  background-color: #f6f9fe;
  padding: 5% 0;
}

.select-wrapper select {
  -webkit-appearance: none;
  appearance: none;
  z-index: 1;
  position: relative;
  background: transparent;
}

.select-wrapper {
  position: relative;
  margin-top: 45px;
}

.select-wrapper::after {
  content: "▼";
  font-size: 1rem;
  top: 10px;
  right: 20px;
  z-index: 0;
  position: absolute;
}

.firstname {
  background: #ffffff;
  border: 1px solid #dee7f4;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 84, 125, 0.1);
  border-radius: 35px;
  width: 100%;
  padding: 10px;
  margin-top: 45px;
}

.check input {
  vertical-align: middle;
}

.signup_check {
  margin-top: 30px;
}

.change_left .lab_change_left {
  padding: 12px 16px;
}

.inputu_boxs .mailSec {
  margin:0;
}

.inputu_boxs .check {
  float: right;
  width:50%;
  margin: 0!important;
  padding: 0 10px;
}

.add_address .check {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

/* .add_address .mailSec {
  width: 45%;
  margin: 0;
} */
/* .editProfile .check{
  margin-top: 0;
} */
.mailSec {
  width: 40%;
  background: #ffffff;
  border: 1px solid #359d9e;
  box-shadow: 0px 10px 20px rgba(0, 84, 125, 0.1);
  border-radius: 35px;
  float: left;
  text-align: left;
  padding: 10px;
  margin-right: 23px;
}

.mailbutton input {
  vertical-align: middle;
  margin-right: 2px;
}

.FemailSec {
  width: 40%;
  float: left;
  background: #ffffff;
  border: 1px solid #dee7f4;
  box-shadow: 0px 10px 20px rgba(0, 84, 125, 0.1);
  border-radius: 35px;
  text-align: left;
  margin-left: 10%;
  padding: 10px;
}

.inputdata {
  background: #ffffff;
  border: 1px solid #dee7f4;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 84, 125, 0.1);
  border-radius: 35px;
  width: 100%;
  padding: 10px;
  margin-top: 30px;
}

.dataComponut {
  background: url("../assets/img/calendar.png") 96% no-repeat;
}

/* groupview detail*/
.group_view .includ {
  font-size: 16px;
  color: var(--black);
}

.groupview_top .scan h6 {
  font-size: 18px;
  color: var(--lightgry);
  text-transform: capitalize;
  margin: 0;
}

.groupview_top .scan h5 {
  font-size: 24px;
  color:var(--black);
  text-transform: capitalize;
  font-weight: 600;
  padding: 0;
}
.safebg {
  float: inherit;
  background-color: var(--greendeep);
  padding: 2px 4px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: 15px;
}

.safebg i {
  color: var(--white);
  padding-right: 5px;
  font-size: 15px;
  float: left;
  padding-top: 3px;
}

.safebg .safe {
  color: var(--white);
  font-size: 11px;
  float: right;
}
.groupview_top .viewid{padding: 20px;}
.groupview_top .card_add{
  margin: 3% 0 0 0px;
  width: 33%;
  background: #194A84;
}
.group_detail {
  clear: both;
  margin-top: 20px;
}
.group_detail .scan_allergy{padding: 20px;}
/* LabView Sec */

.scan_des {
  padding-left: 20px;
  font-weight: 400;
  font-size: 18px;
}

.coient_img {
  text-align: center;
  padding: 25px 10px;
}

.lab_view {
  background: #ffffff;
  box-shadow: 0px 4.82036px 38.5629px rgba(31, 88, 141, 0.1);
  border-radius: 9.64072px;
  padding: 20px;
  margin-top: 5%;
}

.scan {
  padding: 0 20px 15px 20px;
}

.scan h6 {
  float: left;
  padding-top: 0px;
  padding-right: 5px;
  font-size: 24px;
  font-weight: 500;
}

.scan img {
  float: left;
}

.ratingbgs {
  float: right;
}

.ratings {
  background: #ee8a53;
  border-radius: 5px;
  color: #ffff;
  font-weight: 500;
  font-size: 16px;
  width: 125%;
  text-align: center;
}

.view p {
  font-weight: normal;
  font-size: 14px;
}

.viewid {
  background: #ffffff;
  box-shadow: 0px 4.82036px 38.5629px rgba(31, 88, 141, 0.1);
  padding: 20px 10px;
  border-radius: 9.64072px;
  margin-top: 10%;
}

.Collections {
  font-weight: 500;
  font-size: 18px;
  color: #ee8a53;
  padding: 7px 5px;
}

.newMaster .Collections {
  font-size: 14px;
}

.home_nomber {
  color: var(--black);
  font-weight: bold;
  font-size: 14px;
  padding: 7px 5px;
}

.home_nombe {
  font-size: 14px;
}

.diagonostic_tests {
  padding: 20px 0;
}

.Booked {
  font-weight: bold;
  font-size: 24px;
  color: #161c2d;
  margin: 0;
  padding: 10px 0;
}

.Knowns {
  background: #ffffff;
  box-shadow: 0px 5px 30px rgb(31 88 141 / 10%);
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 15px !important;
}

.Knowns h5 {
  font-weight: 900;
  font-size: 14px;
  color: #43455e;
}

.Knowns p {
  font-weight: normal;
  font-size: 11px;
  color: #7a7fa8;
}

.Knowns h6 {
  text-align: center;
  font-weight: 900;
  cursor: pointer;
  font-size: 16px;
  color: #ee8a53;
  padding-top: 10px;
}

.boder {
  border: 1px solid #e2e4f0;
}

.button_boxs {
  padding: 15px 0;
}

.button_boxs button {
  width: 10%;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #ee8a53;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 84, 125, 0.1);
  border-radius: 40px;
}

.view-more {
  background-color: var(--white);
  padding: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #ee8a53;
  width: 15%;
  margin: 0 auto;
  border: 1px solid #ee8a53;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 84, 125, 0.1);
  border-radius: 40px;
  margin-top: 5%;
}

.user_reviews h6 {
  font-weight: 500;
  font-size: 24px;
  color: #161c2d;
  margin-top: 3%;
}

.Comprehensive {
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(31, 88, 141, 0.1);
  border-radius: 20px;
}

.Comprehensive h6 {
  font-weight: bold;
  font-size: 15px;
  color: #43455e;
}
.ComprehensiveHome .Myscan h3{
  padding-bottom: 10px;
}
.ComprehensiveHome  h5{
  padding-bottom: 10px;
}
.ComprehensiveHome  .Fasting{
  padding-bottom: 10px;
}
.safety h6 {
  float: left;
  font-weight: 700;
  font-size: 14px;
  color: #43455e;
}

.safe_rule {
  background-color: var(--greendeep);
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  float: right;
  color: var(--white);
  padding: 2px 4px;
  font-size: 12px;
  position: absolute;
  right: 0;
}

.sturation {
  font-weight: normal;
  font-size: 14px;
  color: #7a7fa8;
  padding: 5px 0;
}

.includ {
  color: var(--orange);
  font-size: 14px;
  padding-bottom: 10px;
}

.consol_text {
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  color: var(--orange);
  padding: 8px 0;
}

.consol_left {
  float: left;
}

.consol_left p {
  font-weight: 900;
  font-size: 17px;
  color: var(--black);
}

.consol_left span {
  font-weight: 500;
  font-size: 14px;
  color: var(--lightgry);
  text-decoration: line-through;
}

.consol_right {
  float: left;
  background-color: #e3ffff;
  border: 1px dashed var(--greendeep);
  color: var(--greendeep);
  font-size: 14px;
  padding: 0 5px;
  margin-left: 8px;
}

.card_add {
  background: #ee8a53;
  border-radius: 8px;
  margin: 0 auto;
  width: 95%;
  padding: 10px;
  margin-top: 15%;
  text-align: center;
}

.addcart {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  background: none;
  color: #ffff;
  border: none;
}

.button_view {
  text-align: center;
}

.vishnu {
  background: #5fd0d1;
  border-radius: 20px 20px 0px 0px;
}

.vishnu h6 {
  float: left;
  font-weight: 500;
  font-size: 20px;
  color: #ffff;
  padding: 10px;
}

/* .vishnu i {
    float: right;
    padding: 5px;
    padding-top: 17px;
    color: #ffff;
} */

.vishnus {
  background: #5fd0d1;
  border-radius: 20px 20px 0px 0px;
}

.vishnus h6 {
  float: left;
  font-weight: 500;
  font-size: 20px;
  color: #ffff;
  padding: 10px;
}

.rating_star {
  float: right;
  padding: 10px;
}

.rating_star i {
  float: left;
  padding: 5px;
  padding-top: 17px;
  color: #ffff;
}

.very {
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(31, 88, 141, 0.1);
  border-radius: 20px;
  margin-top: 5%;
}

.vishnu_tex {
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  color: rgba(22, 28, 45, 0.6);
  text-align: center;
  padding: 20x 0;
}

.icon-boxs {
  width: 10%;
  margin: 0 auto;
}

.icon_main {
  margin-bottom: 8%;
}

.round-secs i {
  float: left;
  background: #ffffff;
  border: 1px solid #edf1fd;
  box-shadow: 0px 24px 30px rgba(68, 75, 140, 0.1);
  border-radius: 100%;
  padding: 14px 20px;
  color: #359d9e;
  margin-top: 16px;
  margin-right: 5px;
}

/* SearchResult of test */
.available {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.home, .home a {
  font-size: 36px;
  font-weight: 700;
  color: var(--white);
  text-align: center;
}

.home span {
  padding: 0 5px;
}

.searchbg {
  background: url(../assets/img/bannerbg.svg) 20px no-repeat;
  padding: 55px 0;
  background-size: cover;
}

.search_main {
  width: 80%;
  margin: 0 auto;
}

.home li {
  display: inline-block;
  color: var(--white);
}

.home li a {
  text-decoration: none;
  color: var(--white);
  font-size: 36px;
  font-weight: bold;
}

.home li i {
  font-size: 36px;
}

.location i {
  float: left;
  color: #235289;
  padding: 5px 10px 0 0;
}

.location p {
  color: #235289;
  font-size: 18px;
}

.location {
  width: 72%;
  cursor: pointer;
  /* margin: 0 auto; */
  color: #235289;
  margin-bottom: 11px;
}

.changemain {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 75%;
  /* margin: 0 auto; */
}

.change_left {
  float: left;
  width: 48%;
  background-color: #f8faff;
  border-radius: 50px;
  color: #85a0c8;
  padding: 0px;
  font-size: 16px;
  margin: 0;
}

.change_lefticon {
  float: left;
  width: 100%;
  display: flex;
}

.change_lefticon i {
  padding: 5px;
  color: var(--greendeep);
  float: left;
}

.change {
  font-size: 16px;
  color: #ee8a52;
  margin-right: 20px;
  float: right;
}

.change_right {
  position: relative;
  float: right;
  background-color: #f8faff;
  border-radius: 50px;
  color: #85a0c8;
  margin-top: 2rem;
  padding: 2px;
  width: 48%;
  /* margin: 0 1%; */
}

.searbg i {
  color: var(--white);
  padding-top: 12px;
  background-color: var(--greendeep);
  border-radius: 100%;
  width: 44px;
  height: 44px;
}

.Name {
  float: left;
  padding: 14px 10px 10px 20px;
  background: none;
  border: none;
  outline: none;
  width: 75%;
}

.name {
  float: right;
  margin-left: 2px;
  cursor: pointer;
}

.searchcontain_top {
  padding: 10px 0 0 0;
  background-color: #f6f8fb;
  clear: both;
  padding-top: 2%;
  float: left;
  width: 100%;
}

.search_border {
  border: 1px solid #43455e;
  border-radius: 50px;
  /* width: 16%; */
  float: left;
  background-color: var(--white);
  padding: 5px 5px 5px 15px;
  margin: 0px 3px 10px 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_border p {
  /* float: left; */
  color: var(--black);
  margin-bottom: 0;
  font-size: 16px;
  /* padding: 6px 2px; */
  line-height: 28px;
  font-weight: bold;
  text-transform: capitalize;
}

.search_border button {
  color: var(--white);
  font-size: 20px;
  background-color: #43455e;
  padding: 8px 8px;
  border-radius: 100%;
  /* float: right; */
  cursor: pointer;
  margin-left: 20px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchcontain {
  background-color: #f6f8fb;
  clear: both;
}

.searchmain {
  width: 90%;
  margin: 0 auto;
}

.search_contain_left h2 {
  font-size: 20px;
  margin: 0;
  margin-bottom: 10px;
  color: var(--orange);
  cursor: pointer;
}

.lab_bg {
  background-color: var(--white);
  box-shadow: 0px 5px 30px rgba(31, 88, 141, 0.1);
  border-radius: 20px;
  font-size: 24px;
  padding: 15px;
  margin-bottom: 20px;
}
.radioMobile{
  text-align: right;
}

.lab_bg h4 {
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  color: var(--black);
}

.iso {
  display: flex;
  padding: 5px 0;
}

.iso img {
  height: 20px;
  width: 20px;
}

.iso i {
  float: left;
  color: var(--orange);
  margin-right: 5px;
  font-size: 17px;
}

.iso h5 {
  color: var(--lightgry);
  font-size: 15px;
  padding-left: 10px;
  margin: 0;
  font-weight: 400;
}

.lab_bg h2 a {
  font-size: 24px;
  font-weight: 700;
  color: var(--orange);
  text-decoration: underline;
  padding-top: 10px;
}

.lab_right_sec {
  text-align: right;
  float: right;
}

.rating {
  background-color: var(--orange);
  border-radius: 20px;
  max-width: max-content;
  float: right;
  padding: 3px 10px;
  color: white;
  font-size: 16px;
  /* width: 30%;
    float: right; */
}

.rating p {
  float: left;
  color: var(--white);
  padding-top: 4px;
}

.rating i {
  color: var(--white);
  margin-left: 5px;
}

.lab_right_sec p {
  color: var(--black);
}

.lab_right_sec h4 {
  clear: both;
  width: 100%;
  margin: 0;
  font-size: 18px;
  color: black;
  padding: 8px 0;
}

.lab_right_sec h4 span {
  padding-left: 10px;
  text-decoration: line-through;
  color: var(--lightgry);
  font-size: 16px;
}

.ratingbottom {
  background-color: #e3ffff;
  border: 2px dashed #359d9e;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 7px 17px;
  max-width: max-content;
  float: right;
}

.ratingbottom p {
  color: #359d9e;
  font-size: 16px;
  /* padding: 8px 8px 0 8px; */
  margin: 0;
}

.lab_right_sec button {
  clear: both;
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 40px;
  border: none;
  color: var(--white);
  padding: 18px 50px;
  margin-top: 30px;
}

.search_contain_right h3 {
  color: var(--black);
  font-size: 22px;
  font-weight: 700;
}

.search_contain_right h6 {
  color: var(--lightgry);
  font-size: 18px;
  font-weight: 400;
}
.title.sacn_testad{
  border: none!important;
  background: none;
  padding: 0!important;
  margin-top: 20px!important;
}
.hormonebg {
  background-color: var(--white);
  border-radius: 20px;
  padding: 25px;
  margin: 0;
}
.hormonebg .ui.styled.accordion{
  box-shadow: inherit;
}
.hormone_contain {
  padding: 15px 0;
}
.sacn_testad p {
  margin: 0;
  word-break: break-all;
  width: 80%;
}
.hormonebg .content{
  padding: 0!important;
}
.hormonebg .content p {
  margin: 0;
  word-break: break-all;
  padding: 5px 0 0 0;
}
.hormonebg .content h5 {
  margin: 5px 0;
  color: #359D9E;
  cursor: pointer;
  word-break: break-all;
}

.labSearchPopup {
  position: relative;
  border-radius: 15px;
}

.labSearchPopup button {
  border: none;
  background: var(--white);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  padding: 10px;
  position: absolute;
  right: -15px;
  top: -15px;
}

.labSearchPopup button span {
  font-size: 25px;
  color: var(--orange);
}

.labSearchPopup .Complete {
  padding: 20px;
}

.labSearchPopup h3 {
  font-size: 15px;
  margin: 0;
}

.labSearchPopup h3 span {
  float: right;
  font-weight: bold;
}

.labSearchPopup p {
  font-size: 15px;
  margin: 0;
}

.AddToCartPopup {
  padding: 10px;
  text-align: center;
}

/* Mycart sec */



.bs-stepper-header {
  width: 45%;
  margin: 0 auto;
}

.ui.tabular.menu .item {
  width: 50%;
  padding: 0.071429em 0.428571em !important;
  color: var(--orange);
  border: none;
}

.ui.tabular.menu .item:hover {
  color: var(--orange);
}

.ui.attached:not(.message)+.ui.attached.segment:not(.top) {
  border: none;
  border-radius: 15px;
  margin-top: 10px;
}

.tab_contain {
  background-color: var(--orangelight);
  padding: 10px;
  border-radius: 15px;
  margin-top: 27%;
}

.ui.tabular.menu .active.item {
  color: var(--orange);
  border: none;
  padding: -0.071429em 0.428571em !important;
}

.ui.primary.button, .ui.primary.buttons .button {
  background-color: var(--white);
  border: 1px solid var(--green);
  color: var(--green);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 40px;
}

.ui.attached.tabular.menu {
  border: none;
}

.contenbg {
  background-color: var(--grycontain);
  padding: 5%;
}

.conten {
  width: 90%;
  margin: 0 auto;
}

.conten h2 {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: var(--black);
}

.numberlink {
  text-align: center;
  padding: 20px 0;
}

.numberlink li {
  display: inline-block;
  background-color: var(--graylight);
  padding: 4px 8px;
  border-radius: 100%;
}

.numberlink li a {
  font-size: 18px;
  color: var(--lightgry);
  text-decoration: none;
}

.numberlink li:hover {
  background-color: var(--greendeep);
}

.numberlink li a:hover {
  color: var(--white);
}

.contener_main h2 {
  text-align: center;
  padding-top: 30px;
}

.orderover_sectop {
  padding: 10px 0 !important;
}

.orderover_sec {
  width: 66%;
  padding: 10px 0 13px 0;
}

.orderover_secleft {
  font-size: 33px;
  font-weight: 700;
  padding: 30px 0;
}


.orderover_secleright {
  float: right;
  font-size: 24px;
  font-weight: 500;
}

.orderover_secleright a {
  color: var(--greendeep);
  text-decoration: underline;
  font-weight: bold;
}

.Overview {
  float: left;
  font-weight: 700;
  font-size: 30px;
  margin-top: 6px;
}

.More {
  float: right;
  font-size: 24px;
  font-weight: 700;
}

.More a {
  text-decoration: underline;
  font-size: 24px;
  color: var(--greendeep);
}

.Order {
  margin: 20px 0;
}

.dellet_box {
  background-color: #ffffff;
  border-radius: 15px;
  margin-bottom: 20px;
}

.PATHOLOGY {
  clear: both;
  padding-top: 20px;
}

.LIMITED {
  background-color: var(--orangelight);
  padding: 10px 20px;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}

.TEST {
  font-size: 16px;
  padding: 5px 0;
  font-weight: 500;
}

.PRIVET {
  font-size: 24px;
  padding: 5px 0;
  font-weight: 700;
}

.Schedule {
  float: right;
}

.Schedule i {
  color: var(--orange);
}

.bor_sec {
  float: left;
}

.nomber {
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  margin-top: 18%;
}

.apply {
  float: right;
  border-radius: 20px;
  background-color: #ee8a53;
  font-size: 16px;
  padding: 8px 16px;
  color: var(--white);
  font-weight: 700;
}

.code_top {
  padding: 20px 10px 0 10px;
}

.jack {
  padding: 20px 10px 5px 10px;
}

.Total {
  float: left;
  font-size: 18px;
  color: #7a7fa8;
}

.total {
  float: right;
  font-size: 18px;
  color: #7a7fa8;
}

.discount {
  padding: 5px 10px 20px 10px;
  clear: both;
  float: left;
  width: 100%;
}

.Discount {
  float: left;
  font-size: 18px;
  color: #7a7fa8;
}

.After {
  float: right;
  font-size: 18px;
  color: #7a7fa8;
}

.carry {
  padding: 27px 10px;
  background-color: var(--orangelight);
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  border-top: 1px solid #ee8a53;
}

.Price {
  float: left;
  font-size: 24px;
  font-weight: 700;
}

.crack {
  float: right;
  font-size: 24px;
  font-weight: 700;
}

.supper {
  margin-top: 10%;
  text-align: center;
}

.supper button {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 40px;
  font-size: 24px;
  font-weight: bold;
  border: none;
  color: var(--white);
  width: 100%;
  padding: 12px 0;
}

/* orderviewscreen */

.conten h6 {
  font-size: 24px;
}

.contener_main {
  width: 90%;
  margin: 0 auto;
}

.Patient .active {
  background-color: var(--orangelight);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 15px;
}

.Patients .patient {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 0;
  margin: 0;
}

/* .Overview {
    float: left;
} */

.safe_sec {
  width: 84%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.safeimg {
  float: left;
  margin-right: 10px;
}

.safe_sec h4 {
  font-size: 16px;
  padding-top: 5px;
}

.addpatient .ui.toggle.checkbox label:before{
  background: #5a5858!important;
}

.addpatient h5 {
  float: left;
}
.addpatient h6.yellow {
  float: left;
  font-size: 18px;
  color: var(--orange);
}

.add {
  float: right;
  font-size: 18px;
  color: var(--orange);
  font-weight: bold;
  cursor: pointer;
}
.add .ui.toggle.checkbox input:checked~label:before{
  background-color: #4CAF50!important;
}

.Details {
  float: right;
  padding-top: 12px;
  color: var(--orange);
  cursor: pointer;
}

.info p {
  width: 90%;
}

.Patients {
  padding-left: 10px;
}

.orderview_border .border {
  border: 4px solid #dee7f4 !important;
}

.orderview_border .border.active {
  border: 4px solid #359d9e !important;
}

.patientBox {
  padding: 15px;
}

.patientBox .info {
  /* padding: 10px; */
  padding: 18px 15px 10px 19px;
  border-radius: 10px;
  background: #ffe7d4;
  color: #873000;
}

.slotsBox {
  padding: 10px;
}

.slotsBox .panel {
  background: #ffffff;
  border: 1px solid #dee7f4;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 84, 125, 0.1);
  border-radius: 35px;
  cursor: pointer;
  padding: 7px;
  margin: 5px;
  text-align: center;
}

.slotsBox .panel.active {
  background: #55c595;
  color: #fff;
}

.step2 h5 {
  font-size: 24px;
  width: 70%;
  margin-bottom: 20px;
}

.box-sec {
  padding: 28px 15px;
  background-color: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.satish {
  font-size: 11px;
  color: var(--lightgry);
}

.satishorange {
  font-size: 11px;
  cursor: pointer;
  color: var(--orange);
}

.satishtext {
  font-size: 11px;
  color: var(--black);
}

.You {
  float: left;
  font-size: 24px;
  font-weight: 700;
}

.You span {
  color: var(--orange);
}

.saved {
  float: right;
  margin-right: 32%;
  color: #ee8a52;
}

.coupons_sec p {
  color: var(--orange);
}

.lab_couponssec {
  background-color: var(--orangelight);
  border-radius: 15px;
}

.lab_coupons {
  padding: 10px;
  float: left;
}

.myscanmore_coupons {
  float: right;
  padding: 10px;
}

.newyrarbg {
  background-color: var(--white);
  padding: 0 10px;
  border-radius: 15px;
  margin: 0 10px;
}

.newyrarbg h4 {
  font-size: 19px;
  font-weight: 700;
  margin: 0;
  padding: 5px 0 5px 0;
}

.newyrarbg h5 {
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  margin: 0 0 15px 0;
  background-color: #E3FFFF;
  border: 1px dashed var(--green);
  color: var(--green);
  display: inline-block;
}

.available_coupons {
  background-color: var(--white);
  border-radius: 15px;
  margin-top: 20%;
}

.available_coupons h3 {
  background-color: var(--orangelight);
  padding: 10px 0 10px 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-size: 24px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.available_coupons h4 {
  font-size: 19px;
  font-weight: 700;
  margin: 0;
  padding: 5px 0 5px 10px;
}

.available_coupons h5 {
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  margin: 0 10px 15px 10px;
  background-color: #E3FFFF;
  border: 1px dashed var(--green);
  display: inline-block;
  color: var(--green);
}

.bottom_border {
  border: 1px solid #ccc;
}

.Diagnostics {
  border-radius: 15px;
  margin-top: 90px;
  padding: 17px 0;
  text-align: center;

}

.Diagnostics button {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 40px;
  font-size: 20;
  font-weight: bold;
  border: none;
  color: var(--white);
  width: 50%;
  padding: 10px 0;
}

.LABOFFER {
  text-align: center;
  color: var(--lightgry);
  padding: 12px 0;
  font-size: 15px;
  font-weight: 400;
}

.Remove {
  padding-top: 10px;
}

.limit_left {
  float: left;
}

.limit_right {
  float: right;
}

.complit_top {
  padding: 10px 0;
}

.complit_top h4 {
  padding: 0 17px;
  font-size: 20px;
  color: #3aa39c;
}

.Complete {
  padding: 5px 20px;
}

.Count {
  float: left;
  font-size: 18px;
  width: 85%;
  font-weight: 500;
  color: var(--black);
}

.Code {
  background-color: #dee7f4;
  border-radius: 25px;
}

.Coupon input {
  border: none;
  padding: 7px 0 0 10px;
  color: var(--black);
  font-size: 18px;
  background: none;
  outline: none;
  width: 70%;
}

.complit_mainbox {
  padding: 15px;
}

.samplecollection {
  font-size: 14px;
  color: var(--lightgry);
  padding: 0 20px;
}

.AARTHI {
  float: left;
  padding-right: 15px;
}

.nomber_sec {
  background-color: var(--white);
  border-radius: 15px;
  padding: 10px 0 0 0;
}

.closebutton i {
  background-color: var(--orangelight);
  color: var(--orange);
  cursor: pointer;
  float: right;
  border-radius: 100%;
  padding: 4px 6px;
  text-align: center;
  margin-right: 15px;
}

.Coupon {
  float: left;
  font-size: 18px;
  padding-left: 10px;
  font-weight: 700;
}

.COVID h6 {
  text-align: center;
  padding-top: 20px;
  font-size: 24px;
}

.someone {
  font-size: 18px;
  color: var(--lightgry);
  padding: 2px 0 15px 0;
}

.Positive .No {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 20px;
  padding: 7px 54px;
  color: aliceblue;
  border: none;
  margin: 5px;
  font-weight: bold;
  font-size: 20px;
}

.Positive .Yes {
  border: 1px solid #85a0c8;
  padding: 5px 54px;
  border-radius: 20px;
  color: var(--black);
  background-color: var(--white);
  font-size: 20px;
  margin: 5px;
  font-weight: bold;
}

.collection {
  background-color: #ffffff;
  border-radius: 15px;
  margin-top: 15px;
  text-align: center;
  padding: 35px 0;
  font-size: 20px;
}

.collection h6 {
  color: var(--lightgry);
}

.orderbutton {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 20px;
  padding: 4px 54px;
  color: aliceblue;
  border: none;
  margin-right: 10px;
  font-weight: bold;
  font-size: 24px;
  margin-top: 20px;
}

.center_sedule {
  text-align: center;
}

.center_sedule button {
  font-size: 18px;
  padding: 8px 54px;
  text-transform: capitalize;
}

/* pement processin screen */

.Selectsec {
  text-align: center;
  padding: 4% 0;
}

.Selectsec h2 {
  font-size: 36px;
  color: var(--black);
}

.Selectsec h1 {
  font-size: 48px;
  color: var(--black);
  font-weight: 700;
}

.car_nomber {
  float: right;
}

.car_nomber ul {
  margin: 0;
}

.car_nomber li {
  display: inline-block;
  padding: 0 3px;
}

.Banking {
  background-color: #ffe7d4;
  padding: 30px;
}

.Wallets {
  float: left;
}

.Wallets h5 {
  font-size: 24px;
  color: #43455e;
  margin-left: 20%;
}

.ammount {
  padding: 10px;
}

.Banking h5 {
  margin-left: 30px;
}

.Credit {
  background-color: #fff;
  padding: 40px;
  margin-left: -10px;
}

.Credit h4 {
  font-size: 24px;
  color: var(--greendeep);
}

.New_Card {
  font-size: 18px;
}

.nombur {
  background-color: #dee7f4;
  padding: 17px 20px;
  border-radius: 45px;
  margin-top: 10%;
}

.Card_Number {
  border: none;
  background: none;
  float: left;
  padding-top: 4px;
}

.Select h4 {
  text-align: center;
  font-size: 36px;
}

.Expiry_date {
  float: left;
  background-color: #dee7f4;
  padding: 20px;
  width: 48%;
  border-radius: 40px;
  margin-top: 20px;
}

.Expiry {
  background-color: #dee7f4;
  border: none;
  color: var(--gray);
  font-size: 18px;
}

.expire_right {
  float: right;
}

.Expiry_date .Expiry {
  float: left;
}

.Expiry_date i {
  float: right;
  font-size: 20px;
  padding-top: 5px;
}

.standards {
  padding: 20px 0;
}

.standards i {
  float: left;
  background-color: #359d9e;
  padding: 5px;
  border-radius: 4px;
  color: var(--white);
  margin-right: 10px;
}

.standards p {
  color: var(--black);
  font-size: 16px;
}

.Date {
  background-color: #dee7f4;
  border: none;
  color: var(--gray);
  font-size: 18px;
}

.Country {
  border: none;
  background-color: #dee7f4;
  color: var(--gray);
  font-size: 18px;
}

.Postal {
  border: none;
  background-color: #dee7f4;
  color: var(--gray);
  font-size: 18px;
}

.Make {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 40px;
  padding: 20px;
  text-align: center;
}

.Make_Payment {
  border: none;
  color: var(--white);
  font-size: 24px;
  background: none;
  font-weight: bold;
}

.domestic {
  font-size: 16px;
  text-align: center;
  margin-top: 10%;
}

.card_images {
  text-align: center;
  padding: 10px 0;
}

.card_images ul {
  padding-left: 0;
}

.card_images li {
  display: inline-block;
  padding: 0 4px;
}

/* PaymentSuccesfulScreen */
.paymentsec {
  color: var(--gray);
  padding: 5px 0;
}

.paymentsec_center {
  float: left;
}

.paymentsec_left {
  float: left;
  width: 30%;
  font-size: 18px;
  font-weight: 400;
}

.paymentsec_right {
  float: right;
  font-size: 18px;
  font-weight: 400;
}

.paymenttop_mainbox {
  width: 85%;
  margin: 0 auto;
}

.paymenttop {
  padding: 10% 0;
}

.paybg {
  background: #ffffff;
  box-shadow: 0px 5px 40px rgba(31, 88, 141, 0.1);
  border-radius: 20px;
  padding: 7% 0;
}

.succesful {
  text-align: center;
  position: relative;
  padding: 10px 0;
}

.succesful i {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  border-radius: 100%;
  padding: 20px;
  font-size: 30px;
  color: var(--white);
  position: absolute;
  right: 0;
  margin: -13% 0;
}

.succesful h1 {
  font-size: 30px;
  color: var(--green);
}

.succesful h3 {
  font-size: 24px;
  color: var(--lightgry);
}

.succesful_bottom {
  padding: 10px 12%;
}

.paysec h5 {
  float: left;
  color: var(--lightgry);
  font-size: 18px;
  font-weight: 400;
}

.paysec .colon {
  float: right;
  color: var(--lightgry);
}

.banking {
  text-align: right;
}

.banking h5 {
  color: var(--lightgry);
  font-size: 18px;
  font-weight: 400;
}

.bannerri {
  width: 100%;
}

.bannerri_img {
  height: 383px;
  width: 472px;
}

.jacktop {
  background-color: var(--white);
  padding: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.jacktop .jack {
  padding: 7px 0;
}

.totaltext {
  font-size: 20px;
  color: var(--black);
}

/* profile screen */

.banner_sekent_bg {
  text-align: center;
  padding: 40px 0;
}

.Vinai {
  font-size: 24px;
  color: var(--greendeep);
}

.Strom {
  font-size: 18px;
  color: var(--lightgry);
}

.profilebox {
  width: 60%;
  margin: 0 auto;
}

.selected {
  background: #ffffff;
  box-shadow: 0px 5px 40px rgba(31, 88, 141, 0.1);
  border-radius: 10px;
  padding: 4px 10px 10px 10px;
  text-align: center;
}

.selectedarrow {
  text-align: right;
  color: var(--greendeep);
  font-size: 22px;
}

.selectedbell {
  text-align: center;
  padding: 15px 0;
}

.selectedbell i {
  color: var(--greendeep);
  font-size: 30px;
}

.selected h6 {
  color: var(--greendeep);
  padding-top: 10px;
  font-size: 22px;
}

.Ratingmain {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 10%;
  padding-top: 10px;
  margin-top: 20px;
}

.Rating {
  padding: 10px 0;
  cursor: pointer;
}

.your_rating {
  border-bottom: 1px solid #e5e5e5;
}

.star {
  float: left;
  padding: 5px 10px;
  font-size: 22px;
  color: var(--black);
}

.Orders {
  font-size: 22px;
  float: left;
  padding: 5px 10px;
  color: var(--black);
}

.downarrow {
  float: right;
}

.downarrow i {
  font-size: 25px;
  padding-top: 10px;
}

/* Order conformation page*/

.successful i {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  border-radius: 100%;
  padding: 25px;
  color: var(--white);
  font-size: 30px;
}

.conten_ordercon {
  text-align: center;
  margin: 10% 0;
}

.conten_ordercon h5 {
  color: var(--greendeep);
  padding: 5px 0;
  font-size: 36px;
  font-weight: bold;
}

.order {
  font-size: 24px;
  padding: 5px 0;
  color: var(--lightgry);
}

.conten_ordercon button {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 40px;
  padding: 10px 50px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: var(--white);
  margin: 20px 0 0 0;
}
/* privacypolice page start*/
.privacypolice{padding: 5%;}
.service{padding: 0 2%;}
.Relationship{padding: 0 2%;}


/* privacypolice page end*/



/* QRcode page */

.conten_qrcode {
  width: 57%;
  margin: 0 auto;
  text-align: center;
  padding: 6% 0;
}

.conten_qrcode h5 {
  color: var(--greendeep);
  padding: 20px 0;
  font-size: 36px;
  font-weight: bold;
}

.conten_qrcode button {
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.15);
  border-radius: 40px;
  padding: 10px 50px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: var(--white);
  margin: 20px 0 0 0;
}

.qr_successful {
  width: 45%;
  margin: 0 auto;
}

.qr_successful img {
  float: left;
}

.print {
  background: #ffffff;
  box-shadow: 0px 5px 40px rgba(31, 88, 141, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: inline-flex;
}

.print i {
  float: left;
  margin-right: 5px;
  font-size: 23px;
}

.print h6 {
  color: var(--lightgry);
  padding-top: 4px;
}

/* order histry */

.sinai_strom {
  text-align: center;
  margin: 15px 0;
}

/* .sinai_strom img {} */

.sinai_strom h5 {
  font-weight: bold;
  font-size: 18px;
  color: #359d9e;
  margin: 0;
  padding: 5px 0;
}

.profile_edit {
  border: 1px solid #f8cc60;
  border-radius: 5px;
  padding: 5px 10px;
  width: 65%;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 35px;
}

.profile_edit a {
  text-decoration: none;
}

.profile_edit i {
  float: left;
  color: #f8cc60;
  padding-top: 5px;
}

.profile_edit h6 {
  font-weight: normal;
  font-size: 16px;
  color: #000000;
  padding-left: 25px;
  padding-top: 5px;
}

.labmenu ul {
  list-style: none;
  padding-left: 0;
}

.labmenu li a {
  text-decoration: none;
  color: var(--greendeep);
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  padding: 15px;
  background: #f8f8f8;
  display: block;
}

.labmenu li a.active {
  color: var(--white);
  background: #359d9e;
}

.labmenu li a:hover {
  color: var(--white);
  background: #359d9e;
}

.labmenu i a:hover {
  color: var(--white);
}

.manage {
  margin: 20px 0;
}

.manage .sideImg {
  padding-left: 30px;
  display: inline-block;
}
.manage .orderImg {
  background: url("../assets/img/orderImg.png") no-repeat;
  background-size: 20px;
}
.manage:hover .orderImg, 
.manage .active .orderImg {
  background: url("../assets/img/orderImg_h.png") no-repeat;
  background-size: 20px;
}
.manage .manageImg {
  background: url("../assets/img/manageImg.png") no-repeat;
  background-size: 20px;
}
.manage:hover .manageImg, 
.manage .active .manageImg {
  background: url("../assets/img/manageImg_h.png") no-repeat;
  background-size: 20px;
}
.manage .savedImg {
  background: url("../assets/img/savedImg.png") no-repeat;
  background-size: 20px;
}
.manage:hover .savedImg, 
.manage .active .savedImg {
  background: url("../assets/img/savedImg_h.png") no-repeat;
  background-size: 20px;
}
.manage .signOutImg {
  background: url("../assets/img/signOutImg.png") no-repeat;
  background-size: 20px;
}
.manage:hover .signOutImg, 
.manage .active .signOutImg {
  background: url("../assets/img/signOutImg_h.png") no-repeat;
  background-size: 20px;
}

.signout {
  margin-top: 80%;
  color: #359d9e;
}

.signout a {
  text-decoration: none;
  color: #359d9e;
}

.signout i {
  float: left;
  font-size: 25px;
}

.signout h5 {
  font-weight: 500;
  font-size: 16px;
  color: #359d9e;
  padding-left: 17%;
}

.Completed {
  background: #ffffff;
  box-shadow: 0px 4.82036px 38.5629px rgb(31 88 141 / 10%);
  border-radius: 9.64072px;
  padding: 30px 15px;
  margin-top: 5%;
  margin-bottom: 5%;
}
.Completed .pagination {float: right;}
.checkup_otder {
  background: #ffe7d4;
  border-radius: 20px 20px 0px 0px;
  padding: 10px;
}

.bengaluru {
  background: #ffe7d4;
  border-radius: 20px 20px 0px 0px;
  padding: 15px 20px;
  cursor: pointer;
}

.coient {
  float: left;
  width: 14%;
  border-radius: 36%;
}

.jaynagar {
  float: left;
  width: 54%;
}

.jaynagar h5 {
  margin-left: 6%;
  font-weight: bold;
  font-size: 15px;
  color: #161c2d;
}

.jaynagar h6 {
  margin-left: 6%;
  font-weight: normal;
  font-size: 12px;
  color: #161c2d;
}

.Sept_oder {
  padding: 17px;
}

.repeat {
  float: right;
  padding-top: 7%;
}

.repeat i {
  float: left;
  padding-right: 10px;
  color: var(--orange);
  padding-top: 3px;
}

.repeat h6 {
  float: right;
  padding-right: 10px;
  font-size: 18px;
  color: var(--orange);
  font-weight: 400;
}

.repeat_box {
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(31, 88, 141, 0.1);
  margin-top: 5%;
  border-radius: 18px;
}

.Sept i {
  float: left;
}

.Sept span {
  font-weight: bold;
  font-size: 14px;
  color: #161c2d;
  padding-left: 10px;
}

.clock {
  float: right;
}

.clock i {
  color: #979797;
}

.clock span {
  font-weight: bold;
  font-size: 14px;
  color: #161c2d;
  padding-left: 10px;
}

.more {
  clear: both;
  margin: 10px 0;
  padding: 10px 0;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
}

.fullbody {
  float: left;
}

.fullbody h6 {
  font-weight: 500;
  font-size: 18px;
  color: #161c2d;
}

.fullbody p {
  font-weight: bold;
  font-size: 14px;
  color: #ee8a53;
  float: left;
}

.Orderid h6 {
  float: right;
  font-weight: 500;
  font-size: 14px;
  padding-top: 4px;
}

.Orderid span {
  font-weight: bold;
  font-size: 14px;
  color: #ee8a53;
  padding-left: 20px;
}

.Status h6 {
  float: left;
  font-weight: 500;
  font-size: 14px;
  color: #161c2d;
}

.Status p {
  float: left;
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  color: #3da89c;
  padding-left: 10px;
}

.download {
  float: right;
  cursor: pointer;
}

.download i {
  color: #3da89c;
}

.download span {
  font-weight: 700;
  font-size: 18px;
  color: #3da89c;
  padding-left: 10px;
  font-family: Roboto;
  text-decoration-line: underline;
}

.details h5 a {
  float: right;
  font-weight: 500;
  font-size: 16px;
  color: #0000ff;
  text-decoration-line: underline;
}

/* order history popup */

/* .modal-dialog {
  /* max-width: 628px; 
} */
.Completed .details{ 
  border-top: solid 1px #ccc;
}
.ratepopup{
  padding: 10px;
}
.ratepopup .logsec{
  padding: 10px;
  clear: both;
}
.ratepopup .inputdata{
 min-height: 100px;
 padding: 10px;
 border-radius: 10px;
}
.ratepopup .commentBut{
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.ratepopup .commentBut button{
  display: inline-block;
  width: 100px;
}
.Orderhistorypopup {
  background: #ffffff;
  box-shadow: 0px 4.82036px 38.5629px rgba(31, 88, 141, 0.1);
  margin: 0 auto;
}

.details {
  padding: 10px;
}

.details h6 {
  float: left;
  font-weight: normal;
  font-size: 24px;
  color: #000000;
}

.details i {
  float: right;
  background: #ffe7d4;
  padding: 7px;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #ee8a53;
}

.aarti {
  float: left;
  padding-left: 5px;
}

.month_sec {
  float: right;
}

.jaynagars {
  padding: 20px;
  cursor: pointer;
  clear: both;
}

.jaynagars img {
  width: 60px;
  border-radius: 10px;
}

.coent_img {
  float: left;
}

.aarti h5 {
  font-size: 16px;
  font-weight: bold;
  color: var(--black);
}

.aarti h6 {
  font-weight: 400;
  font-size: 14px;
  color: var(--black);
}

.month_sec i {
  color: var(--lightgry);
}

.month_sec span {
  color: var(--black);
  font-weight: 700;
  padding-left: 4px;
}

.orderid_nomber {
  padding: 20px;
}

.Orders_id {
  float: left;
}

.Orders_id h6 {
  font-weight: 500;
  font-size: 18px;
  color: #161c2d;
}

.Orders_id h6 span {
  font-weight: bold;
  font-size: 16px;
  color: #ee8a53;
  padding-left: 10px;
}

.online {
  float: right;
}

.online h5 {
  font-weight: 500;
  font-size: 16px;
  margin: 5px 0;
}

.online_secs {
  font-weight: 700;
  font-size: 16px;
  color: #3da89c;
  text-transform: capitalize;
  padding-right: 10px;
}

span.online_sec {
  color: var(--orange);
  text-transform: capitalize;
  font-weight: 500;
}

.patient {
  background: #ffe7d4;
  border-radius: 0px;
  padding: 5px;
  margin-top: 10px;
}

.patient h5 {
  font-weight: 500;
  font-size: 19px;
  color: #ee8a53;
  padding: 9px 0 0 10px;
}

.johnson_sec {
  padding: 20px;
}

.johnson {
  float: right;
}

.johnsons {
  float: left;
}

.patient_name {
  font-weight: 500;
  font-size: 16px;
  color: rgba(22, 28, 45, 0.6);
}

.patient_name span {
  font-weight: 600;
  font-size: 16px;
  color: #161c2d;
  padding-left: 10px;
}

.contac {
  font-weight: 500;
  font-size: 16px;
  color: rgba(22, 28, 45, 0.6);
}

.contac span {
  font-weight: 600;
  font-size: 16px;
  color: #161c2d;
  padding-left: 10px;
}

.pathology_test ul {
  margin-left: 17px;
  margin-bottom: 5px;
}

.pathology_test li {
  padding: 3px 0;
}

.pathology_test {
  padding: 0 20px;
}

.pathology_test h5 {
  font-size: 22px;
  font-weight: 400;
  padding-top: 15px;
}

.body_test {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding-top: 10px;
}

.blood_pressure {
  padding: 10px;
}

.blood_pressure i {
  font-size: 10px;
}

.blood_pressure span {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding-left: 10px;
}

.borders {
  border: 0.6px solid rgba(137, 135, 134, 0.3);
}

.downlode {
  width: 100%;
  margin: 0;
}

.downlode button {
  background: linear-gradient(134.17deg, #ee8a53 4.98%, #f39764 94.88%);
  box-shadow: 0px 10px 30px rgba(238, 138, 83, 0.3);
  border-radius: 40px;
  padding: 5px 10px;
  text-align: center;
  border: none;
  margin: 0;
}

.download_reports i {
  color: #ffff;
  font-size: 18px;
}

.download_reports span {
  color: #ffff;
  font-weight: bold;
  font-size: 20px;
  padding-left: 10px;
}

.repeatorder {
  margin: 0 auto;
  width: 60%;
  margin-top: 5%;
}

.repeatorder button {
  background: #ffffff;
  border: 1px solid #ee8a53;
  box-sizing: border-box;
  text-align: center;
  border-radius: 40px;
  padding: 15px 0;
  width: 100%;
  margin-bottom: 30px;
}

.repeat_order {
  background: none;
}

.repeat_order i {
  color: #ee8a53;
  font-size: 18px;
}

.repeat_order span {
  color: #ee8a53;
  font-weight: bold;
  font-size: 20px;
  padding-left: 10px;
}

/* saved lab */

.lab_saved img {
  float: left;
  width: 90px;
  border-radius: 10px;
  margin-right: 10px;
}

.lab_saved i {
  cursor: pointer;
}

.save_rating {
  background-color: var(--white);
  padding: 3px 4px;
  border-radius: 3px;
  width: 15%;
  color: var(--orange);
  font-size: 12px;
}

.private_sec {
  float: left;
}

/* Mannage Address */

.saved_Labs {
  clear: both;
  background: #ffffff;
  box-shadow: 0px 4.82036px 38.5629px rgba(31, 88, 141, 0.1);
  border-radius: 9.64072px;
  margin: 3% 0;
  padding: 30px 0;
}

.man_add {
  padding: 0 20px;
}

.man_add h5 {
  font-weight: normal;
  font-size: 20px;
  color: #000000;
  float: left;
}

.man_add p {
  float: right;
  color: var(--orange);
}

.lab_min {
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(31, 88, 141, 0.1);
  margin-top: 5%;
  border-radius: 18px;
}

.lab_saved {
  background: #ffe7d4;
  border-radius: 20px 20px 0px 0px;
  padding: 15px 20px;
}

.lab_saved a {
  color: var(--black);
}

.coients {
  float: left;
  height: 60px;
  width: 80px;
  position: relative;
}
.coients img{
  width: 60px;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.lab_saved h6 {
  font-weight: bold;
  font-size: 17px;
  cursor: pointer;
  color: #161c2d;
  float: left;
}

.lab_saved i {
  float: right;
  padding-top: 3px;
}

.stars {
  background: #ffffff;
  border-radius: 5px;
  width: 14%;
  padding: 2px;
  margin-left: 17%;
}

.stars {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #ee8a53;
}

.main {
  padding: 10px 20px;
}

.main p {
  font-weight: normal;
  font-size: 14px;
  color: #7a7fa8;
  padding-top: 5px;
}

.p_num h6 {
  float: left;
  font-weight: normal;
  font-size: 14px;
  color: #7a7fa8;
}

.p_numicon {
  float: right;
  color: #ee8a53;
  cursor: pointer;
  padding-right: 10px;
  font-size: 15px;
  padding-top: 3px;
}

.p_numicon a {
  text-decoration: none;
  color: #ee8a53;
}

.main span {
  font-weight: 500;
  font-size: 14px;
  color: #ee8a53;
  padding-left: 6px;
}

.helpful {
  padding: 20px 0;
  text-align: center;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  color: var(--lightgry);
}

.helpfuls {
  padding: 40px 0;
}

.verys {
  margin-top: 0;
}

/* add Address popup */

.inputborder {
  margin-bottom: 18px;
}

.addressBox {
  padding: 10px;
}

.addressBox input {
  width: 46%;
  padding: 10px;
  font-weight: normal;
  font-size: 14px;
  color: rgba(53, 157, 158, 0.5);
  background: #ffffff;
  border: 1px solid rgba(53, 157, 158, 0.5);
  box-shadow: 0px 3px 30px rgba(0, 84, 125, 0.05);
  border-radius: 4px;
}

.addressBox .single input {
  width: 100%;
  margin: 10px 0;
}

.addressBox .formrow {
  float: left;
  width: 50%;
  padding: 7px 0;
}

.addressBox .formrow input {
  width: 98%;
}

.add_address_sec {
  padding: 0 20px 10px 20px;
}

.mainpop {
  padding: 1 rem 0;
}

.btn-primary {
  background: none;
  border: none;
}

.btn-primary:hover {
  background: none;
  border: none;
  color: var(--reddark);
}

.add_address {
  width: 100%;
  padding: 20px 0;
}

.add_address_sec h5 {
  float: left;
  font-weight: bold;
  font-size: 35px;
  color: #359d9e;
  margin-left: 20%;
}

.details button {
  float: right;
  background: #ffe7d4;
  font-size: 20px;
  border-radius: 40px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #ee8a53;
  border: none;
}

.add_address_sec button {
  float: right;
  background: #ffe7d4;
  font-size: 20px;
  border-radius: 40px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #ee8a53;
  border: none;
}

.inputu_name {
  width: 46%;
  padding: 10px;
  margin: 2%;
  font-weight: normal;
  font-size: 14px;
  color: #292828cc;
  background: #ffffff;
  border: 1px solid rgba(53, 157, 158, 0.5);
  box-shadow: 0px 3px 30px rgba(0, 84, 125, 0.05);
  border-radius: 4px;
}

.inputu_names {
  width: 96%;
}

.inputu_error {
  border: 1px solid #ff0000;
}

.inputu_address:focus {
  border: none;
}

.inputu_city {
  width: 40%;
  padding: 10px;
  margin-left: 5%;
  margin-top: 3%;
  font-weight: normal;
  font-size: 14px;
  color: rgba(53, 157, 158, 0.5);
  background: #ffffff;
  border: 1px solid rgba(53, 157, 158, 0.5);
  box-shadow: 0px 3px 30px rgba(0, 84, 125, 0.05);
  border-radius: 10px;
}

.addbox {
  text-align: center;
  float: left;
  width: 100%;
  clear: both;

}

.addbox button {
  border: none;
  width: 25%;
  font-size: 18px;
  padding: 8px 0;
  background: linear-gradient(134.17deg, #55c595 4.98%, #359d9e 94.88%);
  box-shadow: 0px 10px 30px rgba(0, 151, 69, 0.3);
  margin: 15px 0;
  border-radius: 40px;
  text-align: center;
}

.box_button {
  text-align: center;
  color: #ffff;
  font-weight: normal;
  font-size: 24px;
}

/* labpage */

.active {
  background: none;
}

.carousel-control-next,
.carousel-control-prev {
  background: #fff;
  box-shadow: 0px 5px 30px rgb(31 88 141 / 10%);
  border-radius: 100%;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  height: 70px;
  width: 70px;
  padding-top: 3px;
  position: absolute;
  right: 0;
  margin-top: 3%;
  margin-right: -6%;
  margin-left: -72px;
}

.carousel-control-prev-icon {
  background: none;
  width: 1.5rem;
  height: 3.5rem;
}

.carousel-control-prev-icon i {
  color: lawngreen;
  font-size: 50px;
  background: none;
}

.banners_bg {
  background: url("../assets/img/lab_banner.png") no-repeat;
  min-height: 600px;
  padding: 5% 0;
  background-size: cover;
}

.symptoms {
  padding: 0 8%;
}

.symptoms h4 {
  font-size: 38px;
  color: var(--white);
  font-weight: bold;
  padding-top: 20px;
}

.symptoms h5 {
  font-size: 35px;
  color: var(--white);
}

.symptoms h3 {
  font-size: 18px;
  color: var(--white);
}

.current_location {
  padding-top: 40px;
  cursor: pointer;
}

.location_text {
  font-size: 18px;
}

.current_location i {
  color: #39ff88;
  padding: 5px 10px 0 0;
}

.current_location {
  color: #39ff88;
  font-size: 18px;
}

.lab_change_left {
  display: flex;
  align-items: baseline;
  background-color: #f8faff;
  width: 90%;
  border: 1px solid #235289;
  border-radius: 30px;
  color: #85a0c8;
  padding: 5px 12px;
  font-size: 16px;
  margin: 2px 0px 0px 0px;
}

.lab_change_left i {
  color: #235289;
  font-size: 21px;
  float: left;
}

.lab_change_left .autocompleteBox {
  position: relative;
  padding-left: 10px;
  float: left;
  width: 92%;
  z-index: 999;
}

.lab_change_left .autocompleteBox input {
  border: none;
  outline: none;
  background: none;
}

.lab_change_left .autocomplete {
  position: absolute;
  min-width: 400px;
  background: var(--white);
}

.lab_change_right {
  background-color: #f8faff;
  width: 90%;
  border-radius: 30px;
  border: 1px solid #235289;
  color: #85a0c8;
  padding:5px;
  margin: 5px 0;
}
.chip{
background-color: #235289;
}
.lab_change_right i {
color: #235289;
}

.position-relative {
  position: relative !important;
}

.lab_change_right input {
  border: none;
  outline: none;
  width: 80%;
  padding-left: 10px;
  background: none;
  color: black;
}
.lab_change_right input::placeholder{
  color: black;
}

.lab_change_right .name {
  cursor: pointer;
}

.lab_change_left .form-control {
  padding: 0;
}

.name i {
  background-color: var(--greendeep);
  border-radius: 100%;
  /* width: 47px;
  height: 47px; */
  text-align: center;
  padding: 14px;
  color: var(--white);
}

.offerimg {
  float: left;
  padding-right: 10px;
}

.offers p {
  font-size: 30px;
  color: var(--white);
}

.Myscan {
  padding-top: 20px;
}

.Myscan h3 {
  float: left;
  width: auto !important;
}

.ratingbg {
  background-color: var(--orange);
  float: inherit;
  margin-left: 10px;
  border-radius: 5px;
  padding: 2px 5px;
}

.ratingbg i {
  color: var(--white);
  font-size: 11px;
  float: right;
  padding-top: 3px;
  padding-left: 3px;
}

.ratingpoint {
  float: left;
  color: var(--white);
  font-size: 11px;
}

.Comprehensive_right {
  margin-top: 6%;
}

.Comprehensive {
  padding: 20px 10px;
  margin-top: 10%;
  background: var(--white);
  border-radius: 15px;
  position: relative;
}

.Checkuptext {
  font-size: 18px;
  color: var(--black);
  float: left;
  font-weight: bold;
}

.Includes {
  font-size: 14px;
  color: var(--gray);
  padding-top: 5px;
}

.Transferrin {
  clear: both;
  padding-top: 10px;
  font-size: 14px;
  color: var(--gray);
}

.nitrites {
  padding-top: 10px;
  font-size: 14px;
}

.Glucose {
  padding-top: 10px;
  font-size: 14px;
}

.Comprehensive h5 {
  font-size: 14px;
  margin: 0;
  cursor: pointer;
  color: var(--black);
}
.Comprehensive h5 span{
  display: inline-block;
    padding: 5px 10px;
    border: 1px solid var(--greendeep);
    border-radius: 15px;
}
.Fasting {
  clear: both;
  float: left;
  width: 100%;
}

.Fasting h6 {
  float: left;
  font-size: 18px;
  color: var(--black);
}

.Fasting h6 span {
  font-size: 16px;
  display: inline-block;
  padding-left: 10px;
  color: var(--gray);
  text-decoration: line-through;
}

.Cupon {
  font-size: 14px;
  clear: both;
  display: inline-block;
  padding: 5px 10px;
  background: #e3ffff;
  border: 1px dashed var(--greendeep);
}

.cart {
  text-align: center;
  background: #235289;
  border-radius: 8px;
  padding: 10px;
  font-weight: 400;
  margin-top: 20px;
  color: var(--white);
  border: none;
  font-size: 18px;
  width: 100%;
}

.Compare {
  margin-left: 30%;
  padding-top: 20px;
}

.Compare_box {
  float: left;
}

.Compare p {
  color: var(--gray);
  font-size: 17px;
}

.Sturation {
  margin-top: 10%;
  background-color: var(--white);
  border-radius: 15px;
  padding-left: 10px;
}

.Qualitative {
  background-color:rgba(86,204,242, .40);
  border-radius: 20px;
  padding: 20px 40px;
  position: relative;
  margin-top: 25px;
}

.Qualitative h6 {
  font-size: 25px;
  color: #194A84;
  font-weight: 600;
  padding: o;
  margin: 0;
}

.Qualitative .carousel-control-next,
.Qualitative .carousel-control-prev {
  margin: 0;
  top: 35%;
}

.Qualitative .carousel-control-prev-icon i {
  color: #ed8a52;
  font-size: 30px;
  padding: 10px 0 0 0;
}

.Qualitative .carousel-control-next {
  right: -70px;
}

.Qualitative .carousel-control-prev {
  left: -70px;
}

.Throat {
  padding: 15px 10px 10px 10px;
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(31, 88, 141, 0.1);
  border-radius: 20px;
}

.covid_title {
  font-size: 15px;
  color: var(--black);
  font-weight: 800;
}

.Known {
  font-size: 12px;
  color: var(--gray);
  padding: 10px 0;
}

.lab_boder {
  border: 1px solid #e2e4f0;
  margin: 5px 0 3px 0;
}

.Swab {
  font-size: 18px;
  color: var(--greendeep);
  text-align: center;
}

.Manajan {
  float: left;
  margin-right: 7px;
}

.great {
  font-size: 13px;
  color: var(--black);
  padding-top: 5px;
}

.great a {
  color: var(--black);
}

.Nabh {
  font-size: 12px;
  text-transform: uppercase;
  color: var(--greendeep);
}

.ratebg {
  background-color: var(--orange);
  float: inherit;
  border-radius: 5px;
  padding: 2px 5px;
  margin-top: 5px;
  font-size: 14px;
}

.ratebg i {
  color: var(--white);
  font-size: 11px;
  float: right;
  padding-top: 3px;
  padding-left: 3px;
  font-size: 14px;
}

.ratebg .ratingpoint {
  font-size: 14px;
}

.Diagnostic {
  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(31, 88, 141, 0.1);
  border-radius: 20px;
  cursor: pointer;
  padding: 20px;
}

.Imaging {
  background: var(--white);
  border-radius: 15px;
  padding: 10px;
  box-shadow: 10px 10px 5px grey;
}

.People {
  background: var(--white);
  border-radius: 15px;
  padding: 10px;
  box-shadow: 10px 10px 5px grey;
}

.Coient {
  background: var(--white);
  border-radius: 15px;
  padding: 10px;
  box-shadow: 10px 10px 5px grey;
}

.book_sample h2 {
  text-align: center;
  font-size: 36px;
}

.Labs {
  padding-bottom: 10px;
}

.labnear_sec {
  margin-bottom: 9%;
}

.Labs h2 {
  float: left;
  font-size: 36px;
  font-weight: bold;
}

.Labs h3 {
  float: right;
  font-size: 24px;
  color: var(--greendeep);
  font-weight: bold;
}

.Booked h6 {
  font-size: 24px;
  color: var(--greendeep);
  padding-bottom: 20px;
}

.book_sec {
  margin: 9% 0;
}

.book_sample {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.certified_sec_top {
  margin: 4% 0 0 0;
}

.certified_sec {
  text-align: center;
}

.certified_sec i {
  background: #ffffff;
  border: 1px solid #edf1fd;
  box-sizing: border-box;
  box-shadow: 0px 24px 30px rgba(68, 75, 140, 0.1);
  border-radius: 100%;
  height: 68px;
  width: 70px;
  padding-top: 13px;
  font-size: 38px;
  color: var(--orange);
}

.certified_sec h3 {
  color: var(--black);
  font-size: 24px;
  padding: 10px 0;
}

.certified_sec p {
  font-size: 18px;
  color: var(--gray);
}

.boder {
  border: 1px solid #e2e4f0;
}

.destop {
  display: block;
}

.mobile {
  display: none;
}

.destopImg {
  display: block!important;
}

.mobileImg {
  display: none!important;
}

.ui.basic.red.button, .ui.basic.red.buttons .button {
  box-shadow: 0 0 0 1px #db2828 inset !important;
  color: #db2828 !important;
  border-radius: 40px;
  margin-right: 10px;
}
@media only screen and (max-width:1280px) {
  .img_box img {
    height: 110px;
    width: 110px;
    margin-top: -20px;
  }
  .heading{
    font-weight: 500;
    font-size: 16px;
  }
  .incididunt_text{
  font-size: 11px;
}
  
}

@media only screen and (max-width: 1400px) {
  *{
    margin: 0;
    padding: 0;
  }
  .offers p {
    font-size: 18px;
  }

  .nav-item a {
    font-size: 17px;
    font-weight: 400px;

  }

  .header_right li a {
    font-size: 15px;
  }

  .cotain_right {
    width: 42%;
  }

  .Checkuptext {
    font-size: 10px;
  }

  .covid_title {
    font-size: 11px;
  }

  .Known {
    font-size: 9px;
  }

  /* homepage */
  .Myscans .homeSerch {
    width: 72%;
  }

  .josh_sec {
    width: 78%;
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
  .conten2_bg{background-size: cover;}
  .header_topsleft {
    text-align: center;
  }

  .header_tops {
    text-align: center;
  }

  .headerbg {
    text-align: center;
  }

  /* .nav-item {
    /* padding: 19px 4px 0 4px; 
  } */

  .box {
    width: 80%;
  }

  .cotain_right {
    width: 42%;
  }

  .inputbg {
    padding: 13px 10px;
    width: 18%;
  }

  .cotain_right {
    width: 47%;
  }

  .inputbg {
    padding: 13px 19px;
    width: 19%;
  }

  .covid_title {
    font-size: 9px;
  }

  .Known {
    font-size: 7px;
  }

  .Qualitative_icon i {
    height: 50px;
    width: 51px;
    padding-top: 10px;
    font-size: 27px;
    margin-top: 9%;
  }

  .Qualitative_icon_right i {
    height: 50px;
    width: 51px;
    padding-top: 10px;
    font-size: 27px;
    margin-top: 9%;
  }

  .Manajan {
    height: 40px;
    width: 40px;
  }

  .great {
    font-size: 10px;
  }

  .Nabh {
    font-size: 11px;
  }

  .ratebg {
    padding: 1px 3px;
  }

  .ratebg .ratingpoint {
    font-size: 10px;
  }

  .ratebg i {
    font-size: 10px;
  }

  .Myscans {
    width: 47%;
    margin-left: 0;
  }

  .incididunt {
    width: 46%;
    margin-top: 4%;
    margin-left: 4%;
  }

  .incididunt h5 {
    font-size: 30px;
  }

  .servic {
    font-size: 17px;
    margin: 10px 0;
  }

  .radiology_img {
    width: 88px;
  }

  .pathology_img {
    width: 88px;
  }

  .heading {
    margin-top: 5px;
  }

  /* new */
  .people_top {
    margin-top: -42%;
  }

  .banner_bg {
    /* min-height: 394px; */
    background-size: cover;
  }

  .Myscans {
    margin-top: 0;
    margin-bottom: 100px;
  }

  .Myscans h5 {
    font-size: 28px;
  }

  .logimg {
    width: 433px;
    height: auto;
  }

  /* aboutus */
  .aboutbanner {
    min-height: 371px;
  }

  .distracted {
    width: 15%;
  }

  .readable {
    margin-top: 1rem !important;
  }

  .readable h3 {
    font-size: 23px;
  }

  .readable p {
    font-size: 12px;
  }

  /* mycart */
  .container_bg {
    padding-top: 72px;
  }
  

  /* orderOverview */
  .Yes {
    padding: 5px 40px;
  }

  .No {
    padding: 5px 40px;
  }
}

@media only screen and (max-width: 1000px) {
  .header_tops {
    text-align: center;
  }

  .footer_right {
    text-align: center;
    padding: 8px 0;
  }

  .live_top {
    width: 18%;
    margin: 0 auto;
  }

  .footer_left {
    text-align: center;
  }

  .otpbg {
    padding: 7% 0;
  }

  .cotain_right {
    width: 100%;
    text-align: center;
  }

  .phoneic_sec {
    width: 23%;
  }

  .otpmainbox {
    width: 70%;
    margin: 0 auto;
  }

  .certified_sec h3 {
    font-size: 17px;
  }

  .certified_sec p {
    font-size: 14px;
  }

  .cardimg_box img {
    height: 36px;
  }

  .All {
    font-size: 9px;
  }

  .Labs h2 {
    font-size: 25px;
  }

  .Labs h3 {
    font-size: 20px;
  }

  /* About us */

  .aboutbanner {
    min-height: 350px;
  }

  .established {
    width: 90%;
  }

  .distracted {
    width: 20%;
    margin: 10px 0px;
  }

  .certify {
    padding-top: 28%;
  }

  /* login */
  .logimg {
    width: 341px;
    height: auto;
  }

  .input-box input {
    font-size: 13px;
  }

  .log_right h3 {
    font-size: 26px;
  }

  .log_right p {
    font-size: 11px;
  }

  .editProfile button.signupSubmit,
  .logsec button.signupSubmit {
    font-size: 15px;
    margin: 20px 0;
  }

  .log_right h4 {
    font-size: 9px;
    margin-top: 8px;
  }

  /* otp */
  .otpmainbox {
    width: 90%;
  }

  .log_right .otpmainbox input {
    width: 17%;
    padding: 4px 15px;
  }

  /* signup */
  .mailSec {
    width: 44%;
    margin-right: 11px;
  }

  .log_right h1 {
    font-size: 30px;
  }

  /* mycart */
  .Overview {
    font-size: 26px;
  }

  .More a {
    font-size: 20px;
  }

  .container_bg {
    padding-top: 87px;
  }

  /* orderOverview */
  .someone {
    font-size: 14px;
  }

  .You {
    font-size: 19px;
  }

  .LABOFFER {
    font-size: 13px;
  }

  .total {
    font-size: 16px;
  }

  .Total {
    font-size: 16px;
  }

  .Discount {
    font-size: 16px;
  }

  .After {
    font-size: 16px;
  }

  .Price {
    font-size: 19px;
  }

  .crack {
    font-size: 19px;
  }

  .No {
    padding: 5px 30px;
  }

  .Yes {
    padding: 5px 30px;
  }

  .orderover_secleft {
    font-size: 30px;
  }

  .orderover_secleright {
    font-size: 21px;
  }

  .orderover_sec {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .inputu_boxs .mailSec {
    margin: 0px 0 7px 10px;
    width: 93%;
  }

  .header_topsleft {
    text-align: center;
  }

  .change_left {
    width: 80%;
  }

  .change_right {
    width: 80%;
    height: 48px;
  }

  .logo {
    margin: 0;
    width: 200px;
    height: 26px;
  }

  .nav-item {
    padding: 5px 2px 0 3px;
  }

  .nav-item a {
    font-size: 12px;
  }

  .header_right li a {
    font-size: 12px;
  }

  .banner_box {
    width: 90%;
    margin: 0 auto;
    float: none;
  }

  .img_box{
    display: flex;
    flex-direction: row;
    align-content:space-between;
  }

  .img_box img {
    height: 52px;
  }

  .phoneic_sec h3 {
    font-size: 16px;
  }

  .phoneic_sec i {
    font-size: 18px;
  }

  .navigation i {
    padding: 20px 10px 20px 20px;
    font-size: 20px;
  }

  .live_top {
    width: 23%;
  }

  .otpmainbox {
    padding: 0;
  }

  .Manajan {
    float: none;
  }

  .Diagnostic {
    text-align: center;
  }

  .ratebg {
    float: right;
  }

  .great {
    font-size: 8px;
  }

  .Nabh {
    font-size: 9px;
  }

  .Diagnostic {
    margin: 5px 0;
  }

  .Myscans .homeSerch {
    width: 83%;
  }

  .homeSerch .autocompleteBox {
    width: 60%;
  }

  .homeSerch .homeSerchName {
    width: 100%;
  }

  .people h5 {
    font-size: 29px;
    margin-bottom: 5%;
  }

  .josh_sec {
    padding-left: 0;
  }

  .JOSH {
    font-size: 16px;
  }

  .icon-box {
    width: 35%;
  }

  .conten2_bg {
    min-height: 0;
  }

  .incididunt h5 {
    font-size: 32px;
  }

  .servic {
    width: 65%;
  }

  .veniam {
    padding: 5px 10px;
  }

  .heading {
    margin-top: 9px;
  }

  .elit {
    padding: 5%;
  }

  /* about us */
  .distracted h5 {
    font-size: 19px;
  }

  .certify {
    padding-top: 37%;
  }

  .team {
    width: 100%;
  }

  /* login */
  .logimg {
    width: 253px;
    height: auto;
  }

  .log_right {
    padding: 6%;
  }

  .log_right h3 {
    font-size: 26px;
  }

  .log_right p {
    padding: 4px 0 5px 0;
    font-size: 9px;
  }

  .log_right input {
    width: 61%;
    font-size: 11px;
  }

  .log_right h4 {
    font-size: 7px;
  }

  .input-box span {
    font-size: 11px;
    padding-right: 3px;
  }

  .input-box input {
    padding: 0.3rem;
  }

  /* otp */
  .log_right .otpmainbox input {
    width: 17%;
    padding: 9px 14px;
  }

  .buttonbox .buttonotp {
    margin: 16px 0;
  }

  /* signup */
  .log_right .firstname {
    width: 100%;
  }

  .log_right .inputdata {
    width: 100%;
  }

  .mailSec {
    width: 100%;
    margin: 2px 0;
  }

  .mailSec input {
    width: auto;
  }

  .log_right h1 {
    font-size: 20px;
  }

  .firstname {
    margin-top: 15px;
  }

  .inputdata {
    margin-top: 15px;
  }

  .check {
    padding-top: 15px;
  }

  .container_bg {
    padding-top: 50px;
  }

  /* orderOverview */
  .bs-stepper-header {
    width: 60%;
  }
}

@media only screen and (max-width: 767px) {


.destopImg {
  display: none!important;
}

.mobileImg {
  display: block!important;
}

  .inputu_boxs .mailSec {
    margin: 0px 0 7px 10px;
    width: 93%;
  }

  .centerMenu{
    padding:30px 0 0 0;
  }
  .centerMenuRight{
    padding:30px 0 0 0;
  }
.footerbottom{
  text-align: center;
}
.cardimg_box{
  text-align: center;
}
  .Diagnostics {
    margin-top: 20px;
  }

  .yellow-lines {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .cardimg_box {
    text-align: center;
  }

  .logo {
    margin: 0;
    width: 143px;
    height: 16px;
  }


  .footerbg {
    text-align: center;
    border-radius: 25px;
  }

  .footer-brand-div {
    margin-bottom: 20px;
  }

  .nav-item {
    padding: 3px 3px 0 3px;
  }

  ul {
    padding-left: 0;
  }

  .nav-item a {
    font-size: 10px;
  }

  .header_right li a {
    font-size: 10px;
  }

  .navigation i {
    padding: 27px 10px 20px 13px;
    font-size: 14px;
  }

  .Myscans {
    width: 100%;
  }

  .Myscans .homeSerch {
    width: 85%;
  }

  .directory h5 {
    font-size: 30px;
  }

  /* .round-box {
    /* width: 10%; 
  } */

  .phoneic_sec {
    width: 30%;
  }

  .conten2_bg {
    min-height: 0;
  }

  .incididunt h5 {
    font-size: 25px;
  }

  .img_box img {
    height: 60px;
  }

  .people {
    margin-top: 20px;
    width: 92%;
  }

  .josh_sec {
    width: 100%;
  }

  .icon-box {
    width: 39%;
  }

  .veniam {
    width: 61%;
    padding: 7px 10px;
  }

  .send_box {
    font-size: 21px;
  }

  .Chat {
    width: 64%;
  }

  .live_top {
    width: 24%;
    margin: 0 auto;
  }

  .otpmainbox {
    width: 78%;
  }

  /* about us */

  .aboutbanner {
    min-height: 248px;
  }

  .established {
    width: 85%;
  }

  /* signup */
  .log_right .firstname {
    width: 100%;
  }

  .log_right .inputdata {
    width: 100%;
  }

}


@media only screen and (max-width: 420px) {
  .our_branches{
    text-align: center;
    font-size: x-large;
    font-weight: 600;
    margin-bottom: 5%;
  }
  
  .container_bg{
    padding-top: 15%;
  }
  /* .body{
    max-width: 420px !important;
  }
  .footerbg{
    min-width: 420px !important;
    width: 100%;
  } */
  .stack_list_1{
    list-style: none;
    font-size: 12px;
    margin-left: 10%;
    width: 100%;
    
  }
  .stack_list_1 li {
   padding: 2px;
   text-align: left;
   max-width: 120px;
  
  }
  
  .branch_list{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
  }
  .branch_grid{
    margin-left: -8px;
  }
  .karnataka{
    list-style: none;
    font-size: 16px;
    font-weight: 800;
    text-decoration: underline;
    text-align: center;
  }
  .andhrapradesh{
    list-style: none;
    font-size: 16px;
    font-weight: 800;
    text-decoration: underline;
    text-align: center;
    
  }
  
  .servic {
    width: 98%;
    font-size: 8px !important;
  }
  .img_box{
    margin: 0 !important;
  }
  .incididunt {
    width: 100%;
    margin-bottom: 1%;
  }
  .servicesss{
    font-size: 24px !important;
  }
  .pathology{
    margin-top : 3px;
  }
  .pathology_img {
    height: 35px !important;
    width: 35px !important;
  }
  .pathology {
    height: 5px;
    width: 5px;
    margin-right: 3%;
  }
  .cust_test{
    margin-top: 2% !important;
    width: 100%;
    margin-bottom: 15% !important;
  }
  .elfsight-app-2aa9fe01-ad80-478d-8b7f-ab3f472c62e8{
    margin-top: 50px;
  }
  .people_top {
    margin-top: 0px;
  }
  .conten3_bg{
    margin-top: 45%;
  }

  .homeFormBox {
    border-radius: 10px;
    margin: 10px 0px;
  }

  .addres {
    padding: 15px 10px;
  }

  .search-suggestion {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    top: 110% !important;
  }
  .branch_menu_item{
    margin-top: 23%;
  }
  .second_menu_item{
    margin-top: 14%;
  }
  .suggestion-text {
    margin: 0 !important;
    width: 100% !important;
    text-align: left !important;
  }

  .cardimg_box img {
    height: 31px;
  }

  .lab_change_right input {
    margin-left: 0;
    width: 75%;
  }

  .lab_change_left .autocomplete {
    text-align: left;
    min-width: 300px;
  }

  .change_lefticon {
    display: flex;
  }

  .home-search {
    margin: 0 !important;
    width: 100% !important;
  }

  .msm-header {
    text-align: left;
    padding-left: 10px;
  }

  .mobileMenu {
    position: relative;
    right: 0;
    background: var(--white);
    padding: 10px;
    max-width: 40%;
    min-width: 200px;
    width: 100%;
    height: 100vh;
    top: 100%;
    box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .mobileMenu ul {
    display: block;
    float: none;
    padding: 0;
    margin: 0;
  }

  .mobileMenu li {
    display: block;
    padding: 8px 0px;
    margin: 0;
    float: none;
  }

  .topMenu {
    height: 200px;
    overflow-x: auto;
  }

  .nav-item.separator {
    display: none;
  }

  .mobileMenu li.line {
    display: none;
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  .destop {
    display: none;
  }

  .mobile {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .nav-item {
    padding: 10px 4px 0 4px;
  }
  .about_links a {
    font-size: 17px !important;
    /* padding-left: 5px; */
    margin: 1%;
  }

  .nav-item .header {
    float: none;
  }


  .nav-item a {
    font-size: 20px;
  }

  .header_right li a {
    font-size: 20px;
  }

  .navigation i {
    padding: 21px 10px 20px 11px;
    font-size: 10px;
  }

  .otpmainbox {
    padding: 0;
    width: 100%;
  }

  .inputbg {
    width: 18%;
  }

  .Profiles {
    width: 73%;
  }

  .Profiles_bottom {
    width: 33%;
  }

  .live_top {
    width: 30%;
  }

  .phoneic_sec {
    width: 32%;
  }

  /* homepage */
  .Myscans {
    width: 100%;
    text-align: center;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
  }

  .Myscans h5 {
    font-size: 25px;
    padding-top: 30px;
  }

  .Myscans p {
    font-size: 14px;
    width: 56%;
    margin: 100% auto 0 auto;
  }

  .directory h5 {
    font-size: 20px;
  }

  .center img {
    height: 20%;
  }

  .searchbg {
    background: url(../assets/img/searchbanner_mobile.png) no-repeat;
    background-size: contain;
  }

  .location {
    width: 80%;
    margin: 30px auto 15px auto;
    text-align: center;
  }

  .changemain {
    width: auto;
  }

  .location i {
    float: none;
  }

  .Myscans .homeSerch {
    margin-left: 0px;
  }

  .Myscans .homeSerch {
    margin: 0 auto;
  }

  .homeSerch .homeSerchName {
    width: 100%;
  }

  .homeSerch i {
    padding: 7px 7px;
    font-size: 20px;
  }

  .homeSerch .autocompleteBox {
    width: 50%;
  }

  .Myscans .homeSerch {
    width: 76%;
  }

  .ipsum {
    width: 77%;
    margin: 0 auto;
    text-align: center;
    color: var(--gray);
    margin-bottom: 10px;
  }

  .incididunt h5 {
    font-size: 25px;
    margin: 0;
  }

  .servic {
    font-size: 10px;
    line-height: 15px;
  }

  .radiology {
    margin-right: 0;
  }
  .conten2_bg {
    background: url(../assets/img/home_services_mobile.svg) no-repeat;
    background-size: contain;
    min-height: 350px;
  }
  
  .conten3_bg{
    background: url(../assets/img/pathology.svg) no-repeat;
    background-size: contain;
    width: 100%;
    height: 400px;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .conten4_bg{
    background: url(../assets/img/radiology.svg) no-repeat;
    background-size: contain;
    min-height: 660px;
    margin-top: -130px;
  }
  .incididunt_text h2 {
    font-size: 12px;
  }
  .path_image{
    height: 25px;
    width: 25px;
  }
  .path_image1{
    height: 25px;
    width: 25px;
  }
  .path_text{
    margin-left: 15%;
    margin-top: 18%;
  }
  .path_text h1 {
    font-size: 10px;
  }
  .path_text2{
    margin-top: 15%;
    text-align: left;
    margin-right: 0 !important;
    width: 600px
  }
  .path_text2 h1 {
    font-size: 10px;
    width: 100%;
  }
  .img_path1{
  margin-top: -6px;
  }
  
  .incididunt_text h6{
    font-size: 6px;
  }
  .book_button {
    margin-top: 10px;
    height: 35px;
    width: 110px;
    font-size: 12px;
  }
  .conten2_bg {
    width: 100%;
  }

  .heading {
    font-size: 5px;
    margin-left: 5% !important;
    margin-top: 5px;
    line-height: 5px;
  }

  .people h5 {
    font-size: 22px;
    padding-top: 5px;
  }

  .text_sec {
    width: auto;
  }

  .JOSH {
    font-size: 12px;
  }

  .RAISED {
    font-size: 12px;
  }

  .Testimonial p {
    padding: 0 10%;
    font-size: 12px;
  }

  .icon-box {
    width: 25%;
  }

  .round-sec i {
    padding: 8px 14px;
  }

  .Testimonial {
    min-height: 263px;
  }

  .elit {
    width: 100%;
  }

  .tempor {
    width: 100%;
  }

  .Contact {
    text-align: center;
    margin-top: 10%;
  }

  .elit p {
    text-align: center;
  }

  .full_name {
    border-radius: 8px;
  }

  .Example {
    border-radius: 8px;
  }

  .labore {
    border-radius: 8px;
  }

  .send_box {
    font-size: 19px;
    padding-left: 10px;
  }

  .veniam {
    width: 36%;
    padding: 15px 12px;
    margin-bottom: 20px;
  }

  .tempor {
    background-color: var(--white);
  }

  .robo {
    padding-bottom: 20px;
  }

  .Chat {
    width: 60%;
  }

  .tempor h6 {
    font-size: 19px;
  }

  .banner_bg {
    background: url("../assets/img/mobile_view.svg");
    background-size: cover;
    margin-bottom: 10px;
    width: 100%;
  }
  .cust_test{
    margin-top: -150px;
  }
  .footer_menu {
    padding-top: 20px;
  }

  .book_button_sec {
    margin-bottom: 30px;
  }

  .docters {
    padding-top: 10%;
  }

  /* Notification page */
  .signout {
    margin-top: 40%;
  }

  .coients {
    margin-right: 10px;
  }

  .jaynagar {
    float: none;
    width: 100%;
  }

  .repeat i {
    font-size: 13px;
    padding-right: 6px;
  }

  .repeat h6 {
    font-size: 15px;
  }

  .repeat {
    padding-top: 0;
  }

  /* orderOverview */

  .Complete .b_Counts {
    float: left;
  }

  .contener_main h2 {
    margin-top: 15%;
  }

  .orderover_sec {
    width: 100%;
  }

  .orderover_secleft {
    font-size: 23px;
  }

  .orderover_secleright {
    font-size: 16px;
  }

  .Diagnostics {
    margin-top: 10px;
  }

  .nomber_sec {
    margin-top: 10px;
  }

  .PRIVET {
    font-size: 14px;
  }

  .limit_right {
    font-size: 12px;
  }

  .TEST {
    font-size: 12px;
  }

  /*end orderOverview */
  /*Start labsearch */
  .symptoms {
    padding-top: 10%;
    text-align: center;
  }

  .symptoms h4 {
    font-size: 25px;
  }

  .symptoms h5 {
    font-size: 21px;
  }

  .symptoms h3 {
    font-weight: normal;
    font-size: 15px;
  }

  .lab_change_left {
    width: 95%;
  }

  .lab_change_right {
    width: 95%;
  }

  .offerimg {
    height: 30px;
    padding-right: 7px;
  }

  .offers {
    padding-left: 10px;
    padding-top: 20px;
  }

  .offers p {
    font-size: 16px;
  }

  .Checkuptext {
    font-size: 7px;
  }

  .safebg i {
    font-size: 8px;
    padding-right: 3px;
    padding-top: 0;
  }

  .safebg .safe {
    font-size: 7px;
  }

  .Includes {
    font-size: 11px;
  }

  .Myscan img {
    height: 10px;
    width: 109px;
  }

  .ratingpoint {
    font-size: 9px;
  }

  .ratingbg {
    padding: 1px 3px;
  }

  .ratingbg i {
    font-size: 7px;
    padding-top: 2px;
  }

  .Transferrin {
    font-size: 11px;
  }

  .Cupon {
    font-size: 11px;
    width: 123px;
  }

  .Comprehensive h5 {
    font-size: 11px;
  }

  .cart {
    padding: 5px;
    font-size: 15px;
  }

  .Booked h6 {
    font-size: 18px;
  }

  .Booked {
    padding: 10px 0;
  }

  .lab_change_left .autocompleteBox {
    margin-left: 0;
  }

  /*End labsearch */
  /* /searchResult */
  .home {
    font-size: 26px;
  }

  .change_left {
    width: 100%;
  }

  .change_right {
    width: 100%;
    margin-top: 10px;
  }

  .searchbg {
    float: left;
    width: 100%;
  }

  .search_contain_left h2 {
    font-size: 26px;
    text-align: center;
  }

  .lab_bg h4 {
    font-size: 18px;
    text-align: center;
  }

  .iso h5 {
    margin-left: 5px;
  }

  .lab_bg h2 a {
    font-size: 20px;
  }

  .lab_right_sec button {
    width: 100%;
    font-size: 18px;
  }

  .lab-center-details {
    align-items: center;
  }

  .lab_right_sec {
    float: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .search_contain_right h3 {
    font-size: 18px;
  }

  .search_contain_right h6 {
    font-size: 15px;
  }

  .available {
    font-size: 22px;
  }

  /* /end searchResult */

  /* myCartscreen */
  .available_coupons h4 {
    text-align: left;
  }

  .available_coupons h5 {
    text-align: left;
  }

  .conten {
    margin-top: 20%;
  }

  .Overview {
    font-size: 22px;
  }

  .More a {
    font-size: 17px;
  }

  .PATHOLOGY {
    padding: 0;
  }

  .numberlink ul {
    padding: 0;
  }

  .numberlink {
    padding: 10px 0;
  }

  .Order {
    margin: 10px 0;
  }

  .LIMITED {
    text-align: center;
  }

  .Count {
    font-size: 15px;
  }

  .Schedule {
    font-size: 13px;
  }

  .nomber {
    margin-top: 9%;
  }

  .Price {
    font-size: 20px;
  }

  .crack {
    font-size: 20px;
  }

  /* orderovervie page */
  .dellet_box {
    margin-top: 5%;
  }

  .Overview {
    font-size: 19px;
  }

  .Details {
    padding-top: 3px;
  }

  .Samples {
    text-align: center;
    margin-top: 10px;
  }

  .Price {
    font-size: 18px;
  }

  .crack {
    font-size: 18px;
  }

  .someone {
    font-size: 14px;
  }

  /* paymentprocessing */
  .Selectsec {
    padding: 16% 0;
  }

  .Selectsec h2 {
    font-size: 27px;
  }

  .Selectsec h1 {
    font-size: 18px;
  }

  /* paymentsuccesful */
  .paymenttop_mainbox {
    margin-top: 10%;
  }

  .succesful h1 {
    font-size: 20px;
  }

  .succesful h3 {
    font-size: 14px;
  }

  .paymentsec_left {
    font-size: 11px;
  }

  .paymentsec_right {
    font-size: 11px;
  }

  .bannerri_img {
    display: none;
  }

  /* profilepage */
  .profilebox {
    width: auto;
  }

  .selected {
    margin: 10px 0;
  }

  .Ratingmain {
    width: 90%;
  }

  /* orderConformation */
  .order {
    font-size: 14px;
  }

  .conten_ordercon h5 {
    font-size: 18px;
  }

  /* qrCode */
  .conten_qrcode {
    width: 90%;
  }

  .qr_successful {
    width: 88%;
  }

  .conten_qrcode {
    font-size: 17px;
  }

  /* aboutuspage */
  .established {
    width: 100%;
    margin-top: 10%;
    text-align: left;
    margin-left: 10%;
    margin-bottom: 10%;
  }

  .distracted {
    width: 100%;
  }

  .team {
    width: 100%;
    text-align: center;
  }

  .john_peter {
    margin: 10px 0;
  }

  .people h5 {
    font-size: 16px;
  }

  .josh_sec {
    padding-left: 0;
  }

  .readable {
    text-align: center;
  }

  /* orderOverview */
  .bs-stepper-header {
    width: 100%;
  }

  .orderover_secleft {
    font-size: 18px;
  }

  .orderover_secleright {
    font-size: 14px;
  }

  .Overview {
    font-size: 17px;
  }

  .add_address_sec h5 {
    font-size: 26px;
    margin-left: 6%;
  }

  .inputu_boxs .mailSec {
    margin: 0px 0 7px 10px;
    width: 93%;
  }
}

.modal-content .modal-body.mainpop {
  padding: 0;
}

.modal.show {
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.bs-stepper .step-trigger {
  padding: 0;
}

.bs-stepper-circle {
  background: #dee7f4;
  color: #7a7fa8;
  font-size: 25px;
}

.active .bs-stepper-circle {
  background: #359d9e;
  color: #fff;
}

.searchWrapper:nth-child(1) {
  margin-top: none;
}

.chip {
  /* width: 30%; */
  padding: auto 10px;
  white-space: normal;
}

.searchWrapper {
  border: none !important;
  max-height: 120px;
  width: 88%;
  overflow-x: auto;
  padding: 0;
}



@media (max-width: 576px) {
  .orderover_sectop{padding: 10px 11px !important;}
  .orderover_secleright{padding: 0;}
  .orderover_secleft{font-size: 17px;}
  .Details{padding-top:0}
  .nav-list a{font-weight: bold;}
  .live a{font-weight: bold;}
  .Qualitative{padding:20px 20px}
  .addcart{font-size: 15px;}
  .allergy h5{font-size: 21px;}
  .scan_allergy h5{font-size: 21px;}
  .scan_allergy span{font-size: 14px;}
  .radioMobile{ text-align: center;}
  .iso{width: 100%; align-items: inherit;}
  .tab_contain, .available_coupons{margin-top: 0;}
  .johnson_sec{ padding: 10px;}
  .month_sec, .Orderid h6{ float: left;}
  .johnson, .online{width: 100%; float: left;}
  .add_address .inputu_name { width: 95%;}
  .add_address .addressBox .formrow { width: 100%;}
  .inputu_boxs .check{    
    float: none;
    width: 100%;
    margin: 0!important;
    padding: 5px 10px;
  }
  .notification_box{padding: 10px !important; position: relative;}
  .notification_box .noti_icon{right: -10px;top: -10px;}
  .notification_box img{width: 30px;}
  .notification_sec .noti_text{width: 76%!important;font-size: 11px!important;}
  .labContainer {padding-left: 20px;}
  .labContainer>.ui.grid {margin-right: -1.5rem;}
  .Completed .pagination{float: none;}
  .log_right {width: 100%;margin-top: 40%;}
  .Count{ width: 70%; font-size: 14px;}
}

.log_right h3 {
  font-size: 31px;
}

.search-suggestion {
  position: absolute;
  background-color: var(--white);
  width: 100%;
  top: 105%;
  border-radius: 10px;
  z-index: 999;
}

.search-suggestion.show {
  display: block !important;
}

.search-suggestion.hide {
  display: none !important;
}

.suggestion-text {
  font-weight: 700;
  font-size: 15px;
  margin: 0;
  padding: 10px 15px;
  color: var(--black);
  cursor: pointer;
}

.suggestion-text:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.lab-center-name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lab-center-details {
  display: flex;
  flex-direction: column;
}

.lab-center-details>* {
  margin-bottom: none;
}

.searchWrapper {
  border: none;
}

.searchWrapper:nth-child(1) {
  margin-top: none;
}

.nav-item .nav-link {
  font-size: 15px;
  font-weight: 600;
  word-break: break-all;
}

.notifications-div {
  margin-right: 4rem !important;
}

.navbar-nav .dropdown:hover>.dropdown-menu {
  display: block;
}
.navbar-light .navbar-nav .nav-link{
  color:#131210;
  font-size: 16px !important;
  font-weight: 400;
}
.navbar-light .navbar-nav .nav-link:hover{
color: #235289 !important;
}

.topMenu {
  top: 30px;
  width: 600px;
  padding:0px;
  background: none;
  border: none;
  color: black;
}

.topMenu .stackable {
  background: url(../assets/img/bannerbg.svg);
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 20px;
  margin-top:5px;
}

.topMenu .column {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.topMenu .line {
  border-right: solid 1px #ccc;
}

.topMenu .nav-link.active {
  color: #235289 !important;
}


@media (max-width: 767px) {
  .topMenu {
    top: 72px;
    width: 100%;
    padding: 10px;
  }

  .navbar-nav .dropdown:hover>.dropdown-menu {
    display: none;
  }

  .navbar-nav .dropdown:hover>.dropdown-menu.show {
    display: block;
    padding: 0px;
  }

  .topMenu .stackable {
    margin-top: 0;
  }

  .topMenu .line {
    border-right: none;
  }

  .Qualitative .carousel-control-next {
    right: -40px;
  }

  .Qualitative .carousel-control-prev {
    left: -40px;
  }
}


@media (max-width: 576px) {
  .rating {
    display: none;
  }
}

@media (max-width: 576px) {
  .iso-rating {
    display: block !important;
  }

}

@media (min-width: 576px) {
  .iso-rating {
    display: none !important;
  }
}

/* add by sunita */
@media (max-width: 576px) {

  /* login */
  .logsec {
    background: url(../assets/img/loginimg.png) no-repeat;
    background-size: 98%;
    padding: 72px 21px;
    background-position: center;
  }

  .logimg {
    display: none;
  }

  .loginbg_sec {
    padding: 0;
  }

  .log_right {
    margin-top: 10%;
  }

  .log_right p {
    text-align: left;
    font-size: 12px;
  }

  .log_right h4 {
    font-size: 12px;
  }

  .log_right h3 {
    text-align: left;
  }

  .log_right h1 {
    text-align: left;
  }

  /* mycart */
  .available_coupons h4 {
    text-align: left;
  }

  .available_coupons h5 {
    text-align: left;
  }

  .sidebar_sec {
    margin: 0 15%;
  }

  .sinai_strom {
    margin-top: 5%;
  }

  .signout {
    margin-top: 30%;
  }

  .change_left {
    width: 85%;
  }

  .change_right {
    width: 85%;
    height: 48px;
  }



}

/* added by avijit */

@media (max-width:412px) {
  .searbg {
    margin-top: -80%;
  }

}

@media (max-width:408px) {
  .searbg {
    margin-top: 1%;
  }

  .change_left {
    width: 90%;
  }

  .change_right {
    width: 90%;

  }

}
.banner_rtpcr{
  background: url(../assets/img/rtpcr_banner.png) no-repeat;
  background-size: contain;
  margin-top: 1%;
  min-height: 635px;
}
.banner_rtpcr p{
  color: white;
  font-size: 60px;
  font-weight: 800;
  padding-left: 3%;
  padding-top: 2%;
  margin-bottom: 0;
}
.nearest__location{
  font-size: 30px;
  color: white;
  font-weight: 600;
  margin-left: 60%;
  margin-top: -25px;
}
.rtpcr_price{
  font-size: 38px;
}
.price_layout-center{
  padding-left: 30%;
}
.price_layout-right{
  float: right;
  
}
.price_layout-left{
  float: left;
}
.liststylessss{
  list-style: none;
  color: white;
  font-size: 22px;
  font-weight: 600;
}
.liststylessss a{
  list-style: none;
  color: white;
  font-size: 26px;
  font-weight: 600;
}
.liststylessss li {
  margin-bottom: -8%;
}
.grid_areas{
  /* margin-right: 15%; */
  float: right;
  margin-top: -4%;
  width: 38%;
}
.package_block{
  margin-bottom: -8%;
}
.samplelogo_image{
  height: 200px;
  width: 200px;
  margin-left: 15%;
  margin-bottom: 12%;
  margin-top: -25%;
}
.samplecollect_text{
  font-size: 38px;
  margin-left: 12%;
  font-weight: 600;
  color: #359D9E;
}
.banner_rtpcr2{
  background: url(../assets/img/rtpcr_banner2.png) no-repeat;
  background-size: contain;
  margin-top: -10%;
  margin-right: -10%;
  min-height: 539px;
}
.package_items_rtpcr{
  font-size: 22px;
  color: white;
  margin-left: 5%;
  padding-top: 1%;
  font-weight: 600;
}
.package_items_rtpcr ul li {
  margin-bottom: 12%;
  list-style: none;
}
.package_items_rtpcr p {
font-size: 38px;
}
.price_rtpcr p {
  text-decoration: line-through;
  color: #04304F;
  font-weight: 800;
  font-size: 50px;
}
.price_rtpcr{
  margin-left: -88%;
}
.actualrtpcr_price{
  font-size: 72px;
  padding: 20%;
}
.package_btn{
  margin-top: 12%;
  margin-left: 55%;
}
